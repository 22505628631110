import { UPDATE_INTERVAL } from "../actions";

const intervalReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_INTERVAL:
            return {
                ...state,
                interval: action.payload
            };
        default:
            return state;
    }
};

export default intervalReducer;
