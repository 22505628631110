import React, { Fragment } from 'react';
import { InformationCircle } from "heroicons-react";

const AlertBar = ({ children, color = 'blue', detailsButton = false, detailsButtonLink = null, className = "", border = false}) => {
    return (
        <Fragment>
            <div className={`${className} ${border ? `border-2 border-${color}-300` : ''} rounded-md bg-${color}-50 p-4`}>
                <div className={`flex`}>
                    <div className={`flex-shrink-0`}>
                        <InformationCircle className={`h-5 w-5 text-${color}-400`}/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className={`text-sm leading-5 text-${color}-700`}>
                            { children }
                        </p>
                        { detailsButton ? <p className="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
                            <a href={detailsButtonLink}
                               className={`whitespace-no-wrap font-medium text-${color}-700 hover:text-${color}-600 transition ease-in-out duration-150`}>
                                Details &rarr;
                            </a>
                        </p> : null }
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default AlertBar;
