import React, { Fragment } from 'react';
import ProfileSection from "../../components/ProfileSection";

const Profile = () => {
    return (
        <Fragment>
            <ProfileSection/>
        </Fragment>
    )
};

export default Profile;
