import React, { Fragment } from 'react';
import { Application, Menu, Redirect } from "../constants";

const FAQSection = () => {
    const faqListColumnOne = [
        {
            question: 'What is Sendpoint?',
            answer:
                `Sendpoint is a <b>submission endpoint</b> that makes receiving contact form submissions in a matter of minutes a real possibility. Don't waste time rolling your own solution when all you need is a form that works. Get a sweet dashboard for your form data too. Forever free!`
        },
        // {
        //     question: 'How can Sendpoint be used by agencies?',
        //     answer:
        //         "Sendpoint offers an agency white-label program that will allow you to display your client's campaign metrics in a professional dashboard while maintaining credibility."
        // }
    ];

    const faqListColumnTwo = [
        {
            question: 'Why use Sendpoint?',
            answer:
                `Creating a form backend is generally time consuming, regardless of your programming skills. Just add your submission endpoint to your HTML forms <code>action</code> attribute, add a couple of <code>name</code> attributes for the fields you'd like to capture, and you're in business. Check out the <u><a href="/docs">docs</a></u> for more details on how to get started.`
        }
    ];
    const renderFAQs = faqList => {
        return faqList.map((faq, key) => {
            return (
                <div key={key} className={`${key > 0 ? 'mt-6' : null}`}>
                    <dt className='text-lg leading-6 font-medium text-gray-900'>{faq.question}</dt>
                    <dd className='mt-2'>
                        <p className='text-base leading-6 text-gray-500'
                           dangerouslySetInnerHTML={{ __html: faq.answer }}/></dd>
                </div>
            );
        });
    };

    return (
        <div className='bg-white'>
            <div
                className='max-w-screen-xl lg:max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-12 sm:px-16 lg:pt-20 lg:pb-28 lg:px-8'>
                <h2 className='text-3xl leading-9 font-extrabold text-gray-900'>Frequently asked questions</h2>
                <div className='mt-6 border-t-2 border-gray-100 pt-10'>
                    <dl className='md:grid md:grid-cols-2 md:gap-8'>
                        <div>{renderFAQs(faqListColumnOne)}</div>
                        <div className='mt-12 md:mt-0'>{renderFAQs(faqListColumnTwo)}</div>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default FAQSection;
