import React, { Fragment } from 'react';

const Divider = ({ text }) => {
    return (
        <Fragment>
			<div className="relative py-10">
				<div className="absolute inset-0 flex items-center" aria-hidden="true">
					<div className="w-full border-t border-gray-300"/>
				</div>
				<div className="relative flex justify-center">
					<span className="px-2 bg-white text-sm text-gray-500">
					  { text }
					</span>
				</div>
			</div>
        </Fragment>
    )
};

export default Divider;
