import React, { Fragment, useContext, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import NotificationContext from '../../context/NotificationContext';
import FormDetails from '../../components/FormDetails';
import { NotificationType } from '../../constants';
import { INotification } from '../../types/notification';
import { emitToastNotification } from '../../utils/notificationUtils';
import { useHistory } from 'react-router-dom';

const FormNew = () => {
    const { state: auth } = useContext(AuthContext);
    const { state: notification, dispatch } = useContext(NotificationContext);
    const history = useHistory();

    const [formMetaData, setFormMetaData]: any = useState({});

    const handleSaveFormMetaData = async (e) => {
        e.preventDefault();

        let params: any = {
            method: 'POST',
            headers: {
                authorization: `Bearer ${auth.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formMetaData)
        };
        const res = await fetch(`/api/forms/new`, params);
        const notification: INotification = await res.json();
        emitToastNotification(dispatch, notification);
        if (notification.type === NotificationType.SUCCESS) {
            history.push('/dashboard/forms');
        }
    };

    const handleChange = (e) => {
        const updatedFormMetaData = {
            ...formMetaData,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        };
        setFormMetaData(updatedFormMetaData);
    };

    return formMetaData ? (
        <Fragment>
            <FormDetails notification={notification} handleChange={handleChange} handleSave={handleSaveFormMetaData} formMetaData={formMetaData} />
        </Fragment>
    ) : null;
};

export default FormNew;
