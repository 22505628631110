import React, {Fragment, useContext, useEffect, useState} from 'react';
import Toast from "../../components/Toast";
import NotificationContext from "../../context/NotificationContext";
import {Cog, DotsVerticalOutline, Plus, X} from "heroicons-react";
import Loader from "../../components/common/Loader";
import {Draggable, DragDropContext, Droppable} from "react-beautiful-dnd";
import AuthContext from "../../context/AuthContext";
import {useParams} from "react-router";
import FormBuilderSlideOver from "../../components/FormBuilderSlideOver";
import { NOTIFICATION_HIDE } from "../../actions";
import { NotificationType } from '../../constants';

const FormBuilder = () => {
    const {state: auth} = useContext(AuthContext);
    const {state: notification, dispatch} = useContext(NotificationContext);
    const params: any = useParams();
    const {formCustomId} = params;

    const defaultInputsArr: any = [
        {"id": 0, "type": "text", "name": "name", "required": true, "placeholder": "Name", "label": "Name"},
        {"id": 1, "type": "text", "name": "email", "required": false, "placeholder": "Email", "label": "Email"},
        {"id": 2, "type": "text", "name": "phone", "required": false, "placeholder": "Phone", "label": "Phone"},
        {"id": 3, "type": "submit", "value": "Submit"}
    ];

    const defaultForm: any = {
        formCustomId: formCustomId,
        data: {
            action: `http://localhost:3000/api/id/${formCustomId}`,
            method: "POST",
            inputs: defaultInputsArr
        }
    };

    const [inputs, setInputs] = useState({} as any);
    const [form, setForm] = useState({} as any);

    const [displaySelectedInput, setDisplaySelectedInput] = useState(false);
    const [selectedInput, setSelectedInput] = useState(null);

    const fetchFormBuilder = async () => {
        const res = await fetch(`/api/forms/form-builder/${formCustomId}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${auth.token}`
            }
        });
        const form = await res.json();
        if (res.ok) {
            setForm(form);
            if (form.data.inputs) {
                setInputs(form.data.inputs);
                setSelectedInput(form.data.inputs[0])
            }
        } else {
            setForm(defaultForm);
            setInputs(defaultInputsArr);
        }
    };

    const handleSaveFormBuilder = async () => {
        const res = await fetch("/api/forms/form-builder/save", {
            method: 'POST',
            headers: {
                authorization: `Bearer ${auth.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        const data = await res.json();
        console.log('FORM_BUILDER_SAVED:', data);
        if (res.ok) {
            dispatch({
                type: 'NOTIFICATION_DISPLAY',
                payload: {title: "Form Builder Saved", message: `${formCustomId} updated successfully`, type: NotificationType.SUCCESS}
            });
            setTimeout(() => {
                dispatch({type: NOTIFICATION_HIDE})
            }, 3000);
        } else {
            dispatch({
                type: 'NOTIFICATION_DISPLAY',
                payload: {title: "Form Builder Error", message: `Unable to save form builder`, type: NotificationType.ERROR}
            });
            setTimeout(() => {
                dispatch({type: NOTIFICATION_HIDE})
            }, 3000);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        const newForm = {...form};
        const newInputs = [...inputs];
        if (!result.destination) {
            return;
        }

        const items = reorder(
            newInputs,
            result.source.index,
            result.destination.index
        );

        newForm.data.inputs = items;
        setInputs(items);
        setForm(newForm);
    };

    const styles = {
        text: "w-full mb-2 appearance-none border border-gray-300 rounded-md px-3 py-2 placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
        submit: "w-full mb-2 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:border-orange-500 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out"
    };

    const handleAddInput: any = (type) => {
        let newInputs: any = [...inputs];
        let newForm: any = {...form};
        if (type === 'text') {
            newInputs.splice(inputs.length - 2, 0,
                {
                    "id": newInputs.length,
                    "type": type,
                    "name": "phone",
                    "required": false,
                    "placeholder": `Phone: ${newInputs.length}`,
                    "label": "Phone"
                }
            )
        }
        if (type === 'submit') {
            newInputs.splice(inputs.length - 2, 0,
                {
                    "id": newInputs.length,
                    "type": type,
                    "value": "Submit"
                }
            )
        }
        newForm.data.inputs = newInputs;
        setInputs(newInputs);
        setForm(newForm);
    };

    const handleDeleteInput = (event) => {
        const newForm = {...form};
        const inputIdToBeDeleted = parseInt(event.currentTarget.parentElement.parentElement.dataset.rbdDraggableId);
        const newInputs = inputs.filter((input, index) => {
            return index !== inputIdToBeDeleted;
        });
        newForm.data.inputs = newInputs;
        setInputs(newInputs);
        setForm(newForm);
    };

    const handleEditInput = (event) => {
        const index = parseInt(event.currentTarget.parentElement.parentElement.dataset.rbdDraggableId);
        setSelectedInput(inputs[index]);
        setDisplaySelectedInput(true);
    };

    const handleSelectedInputSave = (event) => {
        const updatedForm = {...form};
        const updatedInputs = [...inputs];
        const formInputsIndex = updatedForm.data.inputs.findIndex((obj => obj.id == event.id));
        updatedForm.data.inputs[formInputsIndex] = event;
        const inputsIndex = updatedInputs.findIndex((obj => obj.id == event.id));
        updatedInputs[inputsIndex] = event;
        setInputs(updatedInputs);
        setForm(updatedForm);
    }

    useEffect(() => {
        fetchFormBuilder().catch(err => err);
    }, []);

    useEffect(() => {
    }, [inputs]);

    return form && inputs ? (
        <Fragment>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                                <button onClick={() => handleAddInput('text')} type={"button"}
                                        className={`inline-flex inline-flex justify-center py-1.5 px-2.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-400 hover:bg-green-300 focus:outline-none focus:border-green-500 focus:shadow-outline-green active:bg-green-500 transition duration-150 ease-in-out`}>
                                        <span className={`mr-1`}>
                                            <Plus className={"h-4 w-4"}/>
                                        </span>
                                    Text Input
                                </button>
                            </p>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                                <button onClick={() => handleAddInput('submit')} type={"button"}
                                        className={`inline-flex inline-flex justify-center py-1.5 px-2.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-400 hover:bg-green-300 focus:outline-none focus:border-green-500 focus:shadow-outline-green active:bg-green-500 transition duration-150 ease-in-out`}>
                                        <span className={`mr-1`}>
                                            <Plus className={"h-4 w-4"}/>
                                        </span>
                                    Submit Button
                                </button>
                            </p>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                                <button onClick={() => handleSaveFormBuilder()} type={"button"}
                                        className={`inline-flex inline-flex justify-center py-1.5 px-2.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:border-orange-500 focus:shadow-outline-orange active:bg-orange-500 transition duration-150 ease-in-out`}>
                                        <span className={`mr-1`}>
                                            <Plus className={"h-4 w-4"}/>
                                        </span>
                                    Save Form
                                </button>
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2 w-full">
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="bg-white pl-2 pr-4 py-5">
                                <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                                    <Droppable droppableId={"droppable"}>
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                // className={"inline-block align-middle"}
                                                // style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {inputs.map((input, index) => (
                                                    <Draggable key={index} draggableId={index.toString()}
                                                               index={index}>
                                                        {(provided) => (
                                                            <Fragment>
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    {input.label ? <label htmlFor={input.name} className="ml-8 block text-sm font-medium leading-5 text-gray-700">{input.label}</label> : null}
                                                                    <div className={"items-center inline-flex my-1 align-middle w-full"}>
                                                                        <DotsVerticalOutline
                                                                            style={{marginRight: -15}}
                                                                            className={"inline-block align-middle cursor-move text-gray-400 h-6 w-6"}/>
                                                                        <DotsVerticalOutline
                                                                            className={"inline-block align-middle cursor-move text-gray-400 h-6 w-6 mr-1"}/>
                                                                        <input
                                                                            autoComplete={"none"}
                                                                            className={styles[input.type]}
                                                                            required={input.required}
                                                                            type={input.type}
                                                                            name={input.name}
                                                                            placeholder={input.placeholder}/>
                                                                        <Cog onClick={(e) => handleEditInput(e)}
                                                                            className={"inline-block align-middle cursor-pointer text-gray-400 h-5 w-5 ml-2"}/>
                                                                        <X onClick={(e) => handleDeleteInput(e)}
                                                                            className={"inline-block align-middle cursor-pointer text-gray-400 h-4 w-4 ml-2"}/>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormBuilderSlideOver saveCallback={(e) => handleSelectedInputSave(e)}
                                  displayCallback={setDisplaySelectedInput} display={displaySelectedInput}
                                  input={selectedInput}/>
        </Fragment>
    ) : null;
};

export default FormBuilder;
