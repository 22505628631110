import { IFeaturesArr } from '../types';
import { Plan } from './plan';

export const planOneFeatures: Array<IFeaturesArr> = [
	{ description: `${Plan.PLAN_ONE_ENDPOINT_ALLOWANCE} Submission Endpoint` },
	{ description: `${Plan.PLAN_ONE_MONTHLY_SUBMISSION_ALLOWANCE} Monthly Form Submissions` },
	{ description: "Form Analytics Dashboard" },
	{ description: "Email Notifications" },
	{ description: "CSV & JSON Data Export" }
];

export const planTwoFeatures: Array<IFeaturesArr> = [
	{ description: `All ${Plan.PLAN_ONE_DISPLAY} Plan Features Plus...` },
	{ description: `${Plan.PLAN_TWO_ENDPOINT_ALLOWANCE} Submission Endpoints` },
	{ description: `${Plan.PLAN_TWO_MONTHLY_SUBMISSION_ALLOWANCE} Monthly Form Submissions` },
	{ description: "File Uploads" },
	{ description: "SMS Notifications" },
	{ description: "Custom Thank-You Page Redirects" },
	{ description: "Custom Form IDs" }
];

export const planThreeFeatures: Array<IFeaturesArr> = [
	{ description: `All ${Plan.PLAN_TWO_DISPLAY} Plan Features Plus...` },
	{ description: `${Plan.PLAN_THREE_ENDPOINT_ALLOWANCE} Submission Endpoints` },
	{ description: `${Plan.PLAN_THREE_MONTHLY_SUBMISSION_ALLOWANCE} Monthly Form Submissions` },
	{ description: "Client Form Sharing" }
];
