import React, { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import FormCard from './FormCard';
import MiniPagination from "./MiniPagination";
import { Navigation } from '../constants';
import DashboardContext from "../context/DashboardContext";
import { LOADING } from "../actions";

const FormSection = () => {
	const { state, dispatch } = useContext(DashboardContext);
	const [forms, setForms] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);

	const PAGE_SIZE = 9;

	const handleClick = (e, dir) => {
		if (dir === Navigation.NEXT) {
			setCurrentPage(currentPage + 1);
		} else if (dir === Navigation.PREVIOUS) {
			setCurrentPage(currentPage - 1)
		}
	};

	const fetchAllForms = async () => {
		const res = await fetch(`/api/forms?page=${currentPage}&pageSize=${PAGE_SIZE}&sortDir=DESC&sortBy=submissionCount`);
		const data = await res.json();
		setForms(data.payload.forms);
		dispatch({ type: LOADING, payload: false});
	};

	useEffect(() => {
		dispatch({ type: LOADING, payload: true});
		fetchAllForms().catch(err => err);
		dispatch({ type: LOADING, payload: false});
	}, [currentPage]);

	const renderFormCards = () => {
		return forms.map((form, key) => {
			return <FormCard form={form} key={key} />;
		});
	};

	return !state.loading && forms.length ? (
		<Fragment>
			<h3 className='py-5 text-lg leading-6 font-medium text-gray-900'>
				<div className={'inline-block'}>
					<MiniPagination callback={(e, dir) => handleClick(e, dir)} />
				</div>
			</h3>
			<div className={`${forms.length ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out mt-12 grid gap-5 max-w-none mx-auto md:grid-cols-3 sm:grid-cols-2`}>{renderFormCards()}</div>
		</Fragment>
	) : <div>No forms</div>
};

export default FormSection;
