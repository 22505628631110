import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Line } from 'react-chartjs-2';
import Card from './Card';
import AuthContext from '../context/AuthContext';
import { Interval } from "../constants";
import IntervalContext from "../context/IntervalContext";
import { LOADING } from "../actions";

const DashboardChart = () => {

	const { state: auth } = useContext(AuthContext);
	const { state: dashboard, dispatch } = useContext(AuthContext);
	const { state: intervalState } = useContext(IntervalContext);
	const { interval } = intervalState;
	const [userTimezone, setUserTimezone] = useState(auth.timezone);
	const [labelsForInterval, setLabelsForInterval] = useState();
	const [currentTimesliceSubmissions, setCurrentTimesliceSubmissions] = useState(null);
	const [previousTimesliceSubmissions, setPreviousTimesliceSubmissions] = useState(null);

	useEffect(() => {
		fetchTimeSeries().catch(err => err);
		fetchPreviousTimeSeries().catch(err => err);
	}, [userTimezone, interval]);

	useEffect(() => {
		setUserTimezone(auth.timezone);
	}, [auth.timezone]);

	const fetchTimeSeries = async () => {
		const res = await fetch(`/api/submissions/time-series?interval=${interval}`);
		const data = await res.json();
		const counts: any = [];
		const labels: any = [];
		const dataObjAsArr = Object.entries(data.payload);
		dataObjAsArr.map(item => {
			labels.push(item[0]);
			counts.push({ x: item[0], y: item[1] });
		});
		setLabelsForInterval(labels);
		setCurrentTimesliceSubmissions(counts);
		setTimeout(() => dispatch({ type: LOADING, payload: false}), 500);
	};

	const fetchPreviousTimeSeries = async () => {
		const res = await fetch(`/api/submissions/time-series/?interval=${interval}&lookback=true`);
		const data = await res.json();
		const counts: any = [];
		const labels: any = [];
		const dataObjAsArr = Object.entries(data.payload);
		dataObjAsArr.map(item => {
			labels.push(item[0]);
			counts.push({ x: item[0], y: item[1] });
		});
		setPreviousTimesliceSubmissions(counts);
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{ display: false, weight: 0.1 }],
			yAxes: [{ display: false, weight: 0.1 }]
		},
		legend: {
			display: false
		},
		layout: {
			padding: { left: 0, right: 0, top: 10, bottom: 0 }
		}
	};

	const purple = 'rgba(128,90,213,.55)';
	const green = 'rgba(104,211,145,0.25)';

	const generateIntervalDisplayText = (interval) => {
		switch(interval) {
			case Interval.DAILY:
				return Interval.DAY_DISPLAY;
			case Interval.WEEKLY:
				return Interval.WEEK_DISPLAY;
			case Interval.MONTHLY:
				return Interval.MONTH_DISPLAY;
			case Interval.YEARLY:
				return Interval.YEAR_DISPLAY;
			default:
				break;
		}
	}

	const data = {
		labels: labelsForInterval,
		datasets: [
			{
				label: `Current ${generateIntervalDisplayText(interval)}`,
				data: currentTimesliceSubmissions,
				cubicInterpolationMode: 'monotone',
				lineTension: 0.4,
				fill: true,
				backgroundColor: 'rgba(233,216,253,0.25)',
				borderColor: purple,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'round',
				borderWidth: 5,
				pointBorderWidth: 0,
				pointHitRadius: 100,
				pointHoverRadius: 0,
				pointHoverBackgroundColor: purple,
				pointHoverBorderColor: purple,
				pointHoverBorderWidth: 0,
				pointRadius: 1
			},
			{
				label: `Previous ${generateIntervalDisplayText(interval)}`,
				data: previousTimesliceSubmissions,
				cubicInterpolationMode: 'monotone',
				lineTension: 0.4,
				fill: true,
				backgroundColor: 'rgba(165,253,195,0.1)',
				borderColor: green,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'round',
				borderWidth: 5,
				pointBorderWidth: 0,
				pointHitRadius: 100,
				pointHoverRadius: 0,
				pointHoverBackgroundColor: green,
				pointHoverBorderColor: green,
				pointHoverBorderWidth: 0,
				pointRadius: 1
			}
		]
	};

	const lineProps = {
		data,
		options
	} as any;

	return !dashboard.loading ? (
		<Card className={`${currentTimesliceSubmissions && previousTimesliceSubmissions ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out`}>
			<div style={{ height: 300 }}>
				<Line {...lineProps} />
			</div>
		</Card>
	) : <div/>;
};

export default DashboardChart;
