import React, { useContext, useState } from 'react';
import { Interval } from "../constants";
import IntervalContext from "../context/IntervalContext";
import { UPDATE_INTERVAL } from "../actions";

const ButtonGroup = () => {
	const { state, dispatch } = useContext(IntervalContext);
	const { interval } = state;
	const [selected, setSelected] = useState(`button-group-${interval}`);

	const buttonOptions: any = [
		{ name: 'Day', interval: Interval.DAILY },
		{ name: 'Week', interval: Interval.WEEKLY },
		{ name: 'Month', interval: Interval.MONTHLY },
		{ name: 'Year', interval: Interval.YEARLY }
	];

	const handleSelected = (e: any) => {
		const { id } = e.target;
		const { interval } = e.target.dataset;
		setSelected(id);
		dispatch({ type: UPDATE_INTERVAL, payload: interval });
	};

	const renderButtons = ({ buttons = buttonOptions }: { buttons: any }) => {
		return buttons.map((button: any, key: number) => {
			return (
				<button
					type='button'
					data-interval={button.interval}
					data-interval-value={button.name}
					key={key}
					id={`button-group-${button.interval}`}
					onClick={e => handleSelected(e)}
					className={`${selected === `button-group-${button.interval}` ? 'bg-gray-200 ' : 'bg-gray-100'} ${key === 0 ? ' rounded-l-md ' : ''} ${
						key === buttons.length - 1 ? ' rounded-r-md ' : ''
					} border -ml-px relative inline-flex items-center px-4 py-2 border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
				>
					{button.name}
				</button>
			);
		});
	};

	return <span className='relative z-0 inline-flex shadow-sm rounded-md'>{renderButtons(buttonOptions)}</span>;
};

export default ButtonGroup;
