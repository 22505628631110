import React from 'react';

const Button = ({ callback = () => {}, className = null, buttonText = "Change", disabled = false, submit = false, color = "orange", icon = null, light = false, py = 2, px = 3, textSize = 'sm', fontWeight = 'medium', rounded = 'md'}) => {
    let classes = light ? `py-${py} px-${px} border border-gray-300 rounded-${rounded} text-${textSize} leading-4 font-${fontWeight} text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out`
        : `inline-flex justify-center py-${py} px-${px} border border-transparent text-${textSize} leading-4 font-${fontWeight} rounded-${rounded} text-white bg-${color}-600 hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} active:bg-${color}-700 transition duration-150 ease-in-out`;
    return (
        <span className="ml-5 rounded-md shadow-sm">
            <button disabled={disabled} onClick={callback} type={submit ? "submit" : "button"}
                    className={`${className} ${classes} inline-flex`}>
                { icon ? <span className={`mr-1`}>{icon}</span> : null }
                {buttonText}
            </button>
        </span>
    )
};

export default Button;
