import React, { Fragment } from 'react';
import DemoFormCard from "./DemoFormCard";
import { ChartSquareBarOutline } from "heroicons-react";
import DemoSubmissionsTable from "./DemoSubmissionsTable";

const FeaturesSection = () => {

    return (
        <Fragment>
            <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                    <svg className="hidden z-0 lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                         width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                        <defs>
                            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"/>
                    </svg>

                    <div className="relative">
                        <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Deploy forms in minutes
                        </h2>
                        <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                            Setting up a contact form back-end for every project can be time-consuming and really get in the way of shipping your product.
                        </p>
                    </div>

                    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-20 lg:items-center">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                Data collection made easy
                            </h3>
                            <p className="mt-3 text-lg text-gray-500">
                                Manage form data from an an easy-to-use, modern dashboard.
                            </p>

                            <dl className="mt-10 space-y-10">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div
                                            className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      strokeWidth="2"
                                                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Email & mobile notifications
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            Sendpoint.io provides multiple notification channels to make sure you receive updates as quickly as possible.
                                        </dd>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div
                                            className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      strokeWidth="2"
                                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Simple setup
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            It's as easy as adding your unique <b>Submission Endpoint</b> to any HTML form and you're ready to receive form submissions in a matter of minutes - without all of the hassle.
                                        </dd>
                                    </div>
                                </div>
                            </dl>
                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                            <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                                 width="784" height="404" fill="none" viewBox="0 0 784 404">
                                <defs>
                                    <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20"
                                             height="20" patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="4" height="4" className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"/>
                            </svg>
                            <div className="relative mx-auto">
                                <DemoSubmissionsTable/>
                                {/*<CodeSnippet*/}
                                {/*    className={'w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:w-auto lg:max-w-none'}*/}
                                {/*    id={'codesnippet'} name={'codesnippet'} onChange={() => {*/}
                                {/*}} value={'<form action="https://sendpoint.io/id/YOUR_FORM_ID" method="POST">\n' +*/}
                                {/*'\t<input type="text" name="name"/>\n' +*/}
                                {/*'\t<input type="email" name="email"/>\n' +*/}
                                {/*'\t<input type="tel" name="phone"/>\n' +*/}
                                {/*'\t<input type="submit" value="send"/>\n' +*/}
                                {/*'</form>'} key={'codesnippet'} />*/}
                            </div>
                        </div>
                    </div>

                    <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                         width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                        <defs>
                            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"/>
                    </svg>

                    <div className="relative mt-12 sm:mt-16 lg:mt-24">
                        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-20 lg:items-center">
                            <div className="lg:col-start-2">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Visualize form data
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    Whether launching a product, setting up a personal blog, or collecting feedback, the Sendpoint.io dashboard allows you to get a pulse on the performance of your campaigns at a glance.
                                </p>

                                <dl className="mt-10 space-y-10">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                <ChartSquareBarOutline className={'h-6 w-6'}/>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                Contact form metrics
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                View time series data of your form submissions, whether it's hourly, yearly, or anything in between.
                                            </dd>
                                        </div>
                                    </div>

                                </dl>
                            </div>

                            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                                     width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
                                    <defs>
                                        <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20"
                                                 height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200"
                                                  fill="currentColor"/>
                                        </pattern>
                                    </defs>
                                    <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"/>
                                </svg>
                                <div className="relative mx-auto">
                                    <DemoFormCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
};

export default FeaturesSection;
