import React, { useContext, useState } from 'react';
import Logo from '../../assets/img/logos/sendpoint-logo-purple.svg';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Toast';
import NotificationContext from '../../context/NotificationContext';
import { emitToastNotification } from '../../utils/notificationUtils';
import { NotificationType, Redirect } from '../../constants';

const ForgotPassword = () => {
    const { state: auth, dispatch } = React.useContext(AuthContext);
    const { state: notification } = useContext(NotificationContext);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!email) {
            emitToastNotification(dispatch, { title: "Email Required", message: "Please enter an email address.", type: NotificationType.ERROR });
        }

        const payload = {
            email
        };

        const res = await fetch('/api/user/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const notification = await res.json();

        emitToastNotification(dispatch, notification, 10000);

    };

    return !loading ? (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="object-center m-auto lg:block h-12 w-auto" src={Logo} alt="Sendpoint logo" />
                <h2 className="mt-0 text-center text-xl leading-9 font-extrabold text-gray-900">
                    Forgot Password
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form onSubmit={(e: any) => handleSubmit(e)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input
                                    id="email"
                                    type="email"
                                    name={'email'}
                                    value={email}
                                    onChange={(e: any) => setEmail(e.target.value)}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-between">
                            <div className="text-sm leading-5">
                                <Link to={'/register'}
                                      className="font-bold text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    Need an account? Register instead.
                                </Link>
                            </div>
                        </div>
                        <div className="mt-6">
                          <span className="block w-full rounded-md shadow-sm">
                            <button type="submit"
                                    className="w-full flex justify-center py-2 px-5 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:border-indigo-500 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                              Forgot Password
                            </button>
                          </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    ) : null;
};

export default ForgotPassword;
