import React, { Fragment, useState } from 'react';
import { Plan, Interval } from '../constants';
import { Check } from 'heroicons-react';
import Tag from './Tag';
import { IPricingCardProps } from '../types';
import BillingModal from './BillingModal';

const PricingCard = ({
						 title,
						 description,
						 buttonText = 'Get Started',
						 price,
						 plan = Plan.PLAN_ONE,
						 interval = Interval.MONTHLY,
						 featuresArr,
						 highlighted = false,
						 buttonColor = 'gray',
						 annualSavingsText,
						 buttonDisabled = false,
						 showBillingModal = false
					 }: IPricingCardProps) => {

	const [toggleDisplayBillingModal, setToggleDisplayBillingModal] = useState(false);

	const renderFeatures = (features) => {
		return features.map(feature => {
			return (
				<li className="flex space-x-3">
					<Check className={'flex-shrink-0 h-5 w-5 text-green-500'} />
					<span className="text-sm text-gray-500">{feature.description}</span>
				</li>);
		});
	};

	const handleToggleDisplayBillingModal = () => {
		setToggleDisplayBillingModal(!toggleDisplayBillingModal);
	};

	return (
		<Fragment>
			<div
				className={`${highlighted ? 'border-4 border-indigo-600' : 'border border-gray-200'} bg-white rounded-lg shadow-sm divide-y divide-gray-200`}>
				<div className="p-6">
					<div className="flex justify-between">
						<h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
						{annualSavingsText && interval === Interval.ANNUAL ?
							<Tag color={'indigo'} text={annualSavingsText} /> : null}
					</div>
					<p className="mt-4 text-sm text-gray-500">{description}</p>
					<p className="mt-8">
						<span className="text-4xl font-extrabold text-gray-900">${price}</span>
						<span
							className="text-base font-medium text-gray-500">{interval === Interval.MONTHLY ? ' /' + Interval.MONTHLY.toLowerCase() : ' /' + Interval.ANNUALLY.toLowerCase()}</span>
					</p>
					<a onClick={showBillingModal ? () => handleToggleDisplayBillingModal() : null}
					   className={`${buttonDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'} mt-8 block w-full bg-${buttonColor}-600 border border-${buttonColor}-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900`}>{buttonText}</a>
				</div>
				<div className="pt-6 pb-8 px-6">
					<h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
					<ul className="mt-6 space-y-4">
						{renderFeatures(featuresArr)}
					</ul>
				</div>
				<BillingModal
					plan={plan}
					interval={interval}
					callback={handleToggleDisplayBillingModal}
					display={toggleDisplayBillingModal} title={'Payment Information'}
					text={'Enter you credit card information'} buttonText={'Confirm Payment'} />
			</div>
		</Fragment>
	);
};

export default PricingCard;
