import React, { Fragment } from 'react';

const H1 = ({ text, align = 'center', id = 'h1', className = '' }) => {
    return (
        <Fragment>
			<span id={id}
				className={`${className} mt-2 block text-3xl text-${align} leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl`}>{ text }</span>
        </Fragment>
    )
};

export default H1;
