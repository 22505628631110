import React, { useState } from 'react';
import { Interval, Plan, planOneFeatures, planThreeFeatures, planTwoFeatures } from '../../constants';
import PricingCard from "../../components/PricingCard";

const Pricing = () => {
        const [interval, setInterval] = useState(Interval.ANNUAL);

        const handleChangeInterval = (interval: Interval) => {
            return setInterval(interval);
        }

        const selectedIntervalStyle = "relative w-1/2 bg-white border-2 border-gray-300 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8";
        const unSelectedIntervalStyle = "ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8"

        return (
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:flex-col sm:align-center">
                        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                        <p className="mt-5 text-xl text-gray-500 sm:text-center">Get started with our forever free plan or dive right into more features!</p>
                        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
                            <button onClick={() => handleChangeInterval(Interval.MONTHLY)} type="button"
                                    className={interval === Interval.MONTHLY ? selectedIntervalStyle : unSelectedIntervalStyle}>{Interval.MONTHLY_DISPLAY} billing
                            </button>
                            <button onClick={() => handleChangeInterval(Interval.ANNUAL)} type="button"
                                    className={interval === Interval.ANNUAL ? selectedIntervalStyle : unSelectedIntervalStyle}>{Interval.ANNUAL_DISPLAY} billing
                            </button>
                        </div>
                    </div>
                    <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-3">
                        <PricingCard title={Plan.PLAN_ONE_DISPLAY} description={Plan.PLAN_ONE_DESCRIPTION} price={Plan.PLAN_ONE_MONTHLY_PRICE}
                                     featuresArr={planOneFeatures} interval={interval}/>
                        <PricingCard
                            title={Plan.PLAN_TWO_DISPLAY}
                            description={Plan.PLAN_TWO_DESCRIPTION}
                            price={interval === Interval.MONTHLY ? Plan.PLAN_TWO_MONTHLY_PRICE : Plan.PLAN_TWO_ANNUAL_PRICE}
                            featuresArr={planTwoFeatures}
                            interval={interval}
                            annualSavingsText={`48% Discount!`}
                            highlighted
                        />
                        <PricingCard
                            title={Plan.PLAN_THREE_DISPLAY}
                            description={Plan.PLAN_THREE_DESCRIPTION}
                            price={interval === Interval.MONTHLY ? Plan.PLAN_THREE_MONTHLY_PRICE : Plan.PLAN_THREE_ANNUAL_PRICE}
                            featuresArr={planThreeFeatures}
                            interval={interval}
                            annualSavingsText={'Save $59!'}
                        />
                    </div>
                </div>
            </div>

        )
    }
;

export default Pricing;
