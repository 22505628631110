import React, { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import Loader from './common/Loader';
import { Interval, Plan, planOneFeatures, planThreeFeatures, planTwoFeatures } from '../constants';
import PricingCard from './PricingCard';
import Tag from "./Tag";
import DashboardContext from "../context/DashboardContext";

const BillingSection = () => {
    const { state: auth } = useContext(AuthContext);
    const { state } = useContext(DashboardContext);
    const [avatarUrl, setAvatarUrl] = useState(auth.user.avatar);
    const [name, setName] = useState(auth.user.name);
    const [username, setUsername] = useState(auth.user.username);
    const [user, setUser] = useState(auth.user);
    const [selectedPlan, setSelectedPlan] = useState(auth?.user?.plan?.type);
    const [selectedInterval, setSelectedInterval] = useState(auth?.user?.plan?.interval);

    const MONTHLY_SUFFIX = '/mo.';
    const ANNUAL_SUFFIX = '/yr.';

    const handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        const updatedUser = user;
        updatedUser[key] = value;
        setUser(updatedUser);
    };

    useEffect(() => {
        setUser(auth.user);
    }, [auth.user]);

    useEffect(() => {
        if (auth.user.plan) {
            setSelectedPlan(auth.user.plan.type);
            setSelectedInterval(auth.user.plan.interval);
        }
    }, [auth.user.plan]);

    useEffect(() => {
        setName(auth.user.name);
    }, [auth.user.name]);

    useEffect(() => {
        setUsername(auth.user.username);
    }, [auth.user.avatar]);

    useEffect(() => {
        setAvatarUrl(auth.user.avatar);
    }, [auth.user.avatar]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        console.log('USER__:', user);
    };

    const handleSelectPlan = (newPlan: Plan) => {
        setSelectedPlan(newPlan);
    }

    const handleToggleInterval = () => {
        if (selectedInterval === Interval.MONTHLY) {
            setSelectedInterval(Interval.ANNUAL)
        } else if (selectedInterval === Interval.ANNUAL) {
            setSelectedInterval(Interval.MONTHLY);
        }
    }

    const renderPricingCard = (plan: Plan, interval: Interval) => {

        const isCurrentPlan = auth?.user?.plan?.type === plan && auth?.user?.plan?.interval === interval;
        switch (plan) {
            case Plan.PLAN_ONE:
                return <PricingCard title={Plan.PLAN_ONE_DISPLAY}
                                    description={Plan.PLAN_ONE_DESCRIPTION}
                                    buttonColor={!isCurrentPlan ? 'indigo': 'gray'}
                                    buttonText={isCurrentPlan ? "Current Plan" : "Downgrade"}
                                    price={interval === Interval.MONTHLY ? Plan.PLAN_ONE_MONTHLY_PRICE : Plan.PLAN_ONE_ANNUAL_PRICE}
                                    plan={Plan.PLAN_ONE}
                                    interval={interval === Interval.MONTHLY ? Interval.MONTHLY : Interval.ANNUAL}
                                    buttonDisabled={isCurrentPlan}
                                    featuresArr={planOneFeatures}/>
            case Plan.PLAN_TWO:
                return <PricingCard title={Plan.PLAN_TWO_DISPLAY} description={Plan.PLAN_TWO_DESCRIPTION}
                                    buttonColor={!isCurrentPlan ? 'indigo': 'gray'}
                                    buttonText={isCurrentPlan ? "Current Plan" : "Switch to this plan"}
                                    price={interval === Interval.MONTHLY ? Plan.PLAN_TWO_MONTHLY_PRICE : Plan.PLAN_TWO_ANNUAL_PRICE}
                                    plan={Plan.PLAN_TWO}
                                    interval={interval === Interval.MONTHLY ? Interval.MONTHLY : Interval.ANNUAL}
                                    buttonDisabled={isCurrentPlan}
                                    featuresArr={planTwoFeatures}
                                    showBillingModal />
            case Plan.PLAN_THREE:
                return <PricingCard title={Plan.PLAN_THREE_DISPLAY} description={Plan.PLAN_THREE_DESCRIPTION}
                                    buttonColor={!isCurrentPlan ? 'indigo': 'gray'}
                                    buttonText={isCurrentPlan ? "Current Plan" : "Upgrade to this plan"}
                                    price={interval === Interval.MONTHLY ? Plan.PLAN_THREE_MONTHLY_PRICE : Plan.PLAN_THREE_ANNUAL_PRICE}
                                    plan={Plan.PLAN_THREE}
                                    interval={interval === Interval.MONTHLY ? Interval.MONTHLY : Interval.ANNUAL}
                                    buttonDisabled={isCurrentPlan}
                                    featuresArr={planThreeFeatures}
                                    showBillingModal />
        }
    }

    const { plan } = auth?.user;

    return !state.loading ? (
        <Fragment>
            <div className={`${!state.loading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out`}>
                <div className='md:grid md:grid-cols-3 md:gap-6'>
                    <div className='md:col-span-1'>
                        <form onSubmit={(e: FormEvent) => handleSubmit(e)}>
                            <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                <div className='px-4 py-5 bg-white sm:p-6'>
                                    <h3 className="pb-4 text-lg leading-6 font-medium text-gray-900">
                                        Current Plan: <Tag text={`${plan?.type === Plan.PLAN_ONE ? Interval.LIFETIME : plan?.interval} ${plan?.type}`}/>
                                    </h3>
                                    <div className="flex items-center py-4">
                                        <button
                                            onClick={() => handleToggleInterval()}
                                            type="button" aria-pressed="false" aria-labelledby="toggleLabel"
                                            className={`${selectedInterval === Interval.MONTHLY ? "bg-gray-200" : "bg-indigo-600"} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                                            <span className="sr-only">Use setting</span>
                                            {/*On: "translate-x-5", Off: "translate-x-0" */}
                                            <span aria-hidden="true"
                                                  className={`${selectedInterval === Interval.MONTHLY ? "translate-x-0" : "translate-x-5"} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
                                        </button>
                                        <span className="ml-3" id="toggleLabel">
										<span className="text-sm font-medium text-gray-900">{selectedInterval === Interval.MONTHLY ? `${Interval.MONTHLY_DISPLAY} billing` : `${Interval.ANNUAL_DISPLAY} billing`}</span>
										{/*<span className="text-sm text-gray-500">(Save 10%)</span>*/}
									  </span>
                                    </div>
                                    <fieldset>
                                        <legend id="radiogroup-label" className="sr-only">
                                            Plan Details
                                        </legend>
                                        <ul className="space-y-4" role="radiogroup" aria-labelledby="radiogroup-label">
                                            <li onClick={() => handleSelectPlan(Plan.PLAN_ONE)}
                                                id="radiogroup-option-0" tabIndex={0} role="radio" aria-checked="true"
                                                className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                                                <div
                                                    className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm">
                                                            <p className="font-medium text-gray-900">
                                                                {Plan.PLAN_ONE_DISPLAY}
                                                            </p>
                                                            <div className="text-gray-500 text-xs mt-2">
                                                                <ul>
                                                                    <li>- {Plan.PLAN_ONE_MONTHLY_SUBMISSION_ALLOWANCE} monthly submissions</li>
                                                                    <li>- {Plan.PLAN_ONE_ENDPOINT_ALLOWANCE} form endpoint</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                                        <div className="font-medium text-gray-900">${selectedInterval === Interval.MONTHLY ? `${Plan.PLAN_ONE_MONTHLY_PRICE} ${MONTHLY_SUFFIX}`  : `${Plan.PLAN_ONE_ANNUAL_PRICE} ${ANNUAL_SUFFIX}`}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${selectedPlan === Plan.PLAN_ONE ? 'border-indigo-500' : 'border-transparent'} absolute inset-0 rounded-lg border-2 pointer-events-none`}
                                                    aria-hidden="true"/>
                                            </li>

                                            <li onClick={() => handleSelectPlan(Plan.PLAN_TWO)}
                                                id="radiogroup-option-1" tabIndex={-1} role="radio" aria-checked="false"
                                                className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                                                <div
                                                    className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm">
                                                            <p className="font-medium text-gray-900">
                                                                {Plan.PLAN_TWO_DISPLAY}
                                                            </p>
                                                            <div className="text-gray-500 text-xs mt-2">
                                                                <ul>
                                                                    <li>- {Plan.PLAN_TWO_MONTHLY_SUBMISSION_ALLOWANCE} monthly submissions</li>
                                                                    <li>- {Plan.PLAN_TWO_ENDPOINT_ALLOWANCE} form endpoints</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                                        <div
                                                            className="font-medium text-gray-900">${selectedInterval === Interval.MONTHLY ? `${Plan.PLAN_TWO_MONTHLY_PRICE} ${MONTHLY_SUFFIX}`  : `${Plan.PLAN_TWO_ANNUAL_PRICE} ${ANNUAL_SUFFIX}`}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${selectedPlan === Plan.PLAN_TWO ? 'border-indigo-500' : 'border-transparent'} absolute inset-0 rounded-lg border-2 pointer-events-none`}
                                                    aria-hidden="true"/>
                                            </li>

                                            <li onClick={() => handleSelectPlan(Plan.PLAN_THREE)}
                                                id="radiogroup-option-2" tabIndex={-1} role="radio" aria-checked="false"
                                                className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                                                <div
                                                    className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm">
                                                            <p className="font-medium text-gray-900">
                                                                {Plan.PLAN_THREE_DISPLAY}
                                                            </p>
                                                            <div className="text-gray-500 text-xs mt-2">
                                                                <ul>
                                                                    <li>- {Plan.PLAN_THREE_MONTHLY_SUBMISSION_ALLOWANCE} monthly submissions</li>
                                                                    <li>- {Plan.PLAN_THREE_ENDPOINT_ALLOWANCE} form endpoints</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                                        <div
                                                            className="font-medium text-gray-900">${selectedInterval === Interval.MONTHLY ? `${Plan.PLAN_THREE_MONTHLY_PRICE} ${MONTHLY_SUFFIX}`  : `${Plan.PLAN_THREE_ANNUAL_PRICE} ${ANNUAL_SUFFIX}`}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${selectedPlan === Plan.PLAN_THREE ? 'border-indigo-500' : 'border-transparent'} absolute inset-0 rounded-lg border-2 pointer-events-none`}
                                                    aria-hidden="true"/>
                                            </li>
                                        </ul>
                                    </fieldset>
                                </div>
                                {/*<div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>*/}
                                {/*	<button*/}
                                {/*		type={'submit'}*/}
                                {/*		className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                                {/*		Save*/}
                                {/*	</button>*/}
                                {/*</div>*/}
                            </div>
                        </form>
                    </div>
                    <div className='mt-5 md:mt-0 md:col-span-2'>
                        {renderPricingCard(selectedPlan, selectedInterval)}
                    </div>
                </div>
            </div>
        </Fragment>
    ) : null;
};

export default BillingSection;
