import React, { useContext } from 'react';
import FormChart from './FormChart';
import { CogOutline, DuplicateOutline, InboxInOutline } from 'heroicons-react';
import { Link } from 'react-router-dom';
import { IForm } from '../types';
import { Application, NotificationType } from '../constants';
import NotificationContext from '../context/NotificationContext';
import Toast from './Toast';
import { NOTIFICATION_DISPLAY, NOTIFICATION_HIDE } from "../actions";
import DashboardContext from "../context/DashboardContext";


const FormCard = ({ form }: { children?: any; form: IForm }) => {
	const { state: notification } = useContext(NotificationContext);
	const { state: dashboard, dispatch } = useContext(DashboardContext);

	const handleCopyToClipboard = async () => {
		const endpoint = `${Application.ENDPOINT_PREFIX}/${form.customId}`;
		const event = await navigator.clipboard.writeText(endpoint);
		dispatch({ type: NOTIFICATION_DISPLAY, payload: { title: "Copied Submission Endpoint", message: `${endpoint}`, type: NotificationType.SUCCESS } });
		setTimeout(() => {
			dispatch({ type: NOTIFICATION_HIDE })
		}, 5000);
	};

	return !dashboard.loading ? (
		<div className='bg-white overflow-hidden shadow rounded-lg'>
			<Link to={`/dashboard/forms/${form.customId}`}>
				<div className='px-4 py-2 sm:px-4'>
					<div className="flex inline-block justify-between">
						<h4 className={'font-bold'}>{form.formName}</h4>
						<div className={'inline-block align-middle justify-end'}>
							<InboxInOutline className={'inline-flex text-gray-500 h-3.5 w-3.5 mr-1'} />
							<p className={'inline-flex text-gray-500 text-xs'}>{form.submissionCount}</p>
						</div>
					</div>
				</div>
				<div>
					<FormChart formCustomId={form.customId} />
				</div>
			</Link>
			<div className='border-t border-gray-200 px-4 py-2 cursor-pointer text-center'>
				<div className="flex justify-between">
					<p onClick={() => handleCopyToClipboard()} className={'cursor-pointer'}>
						<DuplicateOutline className={'text-left h-4 w-4 text-gray-400 mr-1 leading-none'} />
					</p>
					<p onClick={() => handleCopyToClipboard()} style={{ fontSize: '.7rem' }}
					   className={'inline-block text-center font-mono text-gray-400 font-semibold select-all cursor-pointer'}>{`${Application.ENDPOINT_PREFIX}/${form.customId}`}</p>
					<div className="justify-start float-left z-10">
						<Link to={`/dashboard/forms/${form.customId}/settings`}>
							<CogOutline className={'text-left h-4 w-4 text-gray-400 mr-1 leading-none'} />
						</Link>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default FormCard;
