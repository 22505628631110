import {
	CURRENT_SUBMISSION_PAGE,
	LOADING,
	SUBMISSION_CURRENT_ID,
	SUBMISSION_DISPLAY_MODAL,
	SUBMISSION_NEXT,
	SUBMISSION_PAGE_SIZE,
	SUBMISSION_PREV
} from "../actions";

const dashboardReducer = (state: any, action: any) => {
	switch (action.type) {
		case LOADING:
			return {
				...state,
				loading: action.payload
			}
		case SUBMISSION_DISPLAY_MODAL:
			return {
				...state,
				displaySubmissionModal: action.payload
			};
		case SUBMISSION_CURRENT_ID:
			return {
				...state,
				currentlyDisplayedSubmissionId: parseInt(action.payload)
			};
		case SUBMISSION_PREV:
			if (parseInt(state.currentlyDisplayedSubmissionId) - 1 < 0) {
				return {
					...state,
					displaySubmissionModal: false
				};
			}
			return {
				...state,
				currentlyDisplayedSubmissionId: parseInt(action.payload) - 1
			};
		case SUBMISSION_NEXT:
			if (parseInt(state.currentlyDisplayedSubmissionId) + 1 > parseInt(state.submissionPageSize) - 1) {
				return {
					...state,
					displaySubmissionModal: false
				};
			}
			return {
				...state,
				currentlyDisplayedSubmissionId: parseInt(action.payload) + 1
			};
		case SUBMISSION_PAGE_SIZE:
			return {
				...state,
				submissionPageSize: parseInt(action.payload)
			};
		case CURRENT_SUBMISSION_PAGE:
			return {
				...state,
				currentSubmissionPage: parseInt(action.payload)
			};
		default:
			return state;
	}
};

export default dashboardReducer;
