import React, { Fragment, useContext, useState } from 'react';
import Toast from './Toast';
import NotificationContext from '../context/NotificationContext';
import { NOTIFICATION_DISPLAY, NOTIFICATION_HIDE } from '../actions';
import { emitToastNotification } from '../utils/notificationUtils';
import { INotification } from '../types/notification';

const BillingModal = ({ display = false, title, text, buttonText, plan, interval, callback }) => {

    const { state: notification, dispatch } = useContext(NotificationContext)
    const [card, setCard] = useState({ number: '', expiration: '', cvc: '', country: 'USA', zip: ''});

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        console.log('name:', name);
        console.log('value:', value);
        const updatedCard = {...card};
        updatedCard[name] = value;
        setCard(updatedCard);
        console.log('updatedCard:', updatedCard);
    }

    const handleSubmit = async () => {
        const payload = {
            plan,
            interval,
            card
        };

        const res = await fetch('/billing/subscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (!res.ok) {
            const notification: INotification = await res.json();
            return emitToastNotification(dispatch, notification);
        }

        const data = await res.json();
        console.log('data:', data);
    }

    return (
        <Fragment>
            <div className={`${display ? 'z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className={`${display ? 'opacity-100 ease-out duration-300' : 'opacity-0 ease-in duration-200'} fixed inset-0 transition-opacity`}
                        aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"/>
                    </div>

                    {/*This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true">&#8203;</span>
                    <div
                        className={`${display ? 'opacity-100 translate-y-0 sm:scale-100 ease-in duration-200' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ease-out duration-300'} inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6`}
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div>
                            <div
                                className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/*Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    {title}
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {text}
                                    </p>
                                </div>
                                <div className="mt-2">
                                    <fieldset>
                                        <legend className="block text-sm font-medium text-gray-700">Card Details
                                        </legend>
                                        <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                                            <div>
                                                <label htmlFor="card-number" className="sr-only">Card number</label>
                                                <input
                                                    value={card.number}
                                                    onChange={(e) => handleUpdate(e)}
                                                    type="text" name="number" id="number"
                                                       className="border focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                                       placeholder="Card number"/>
                                            </div>
                                            <div className="flex -space-x-px">
                                                <div className="w-1/2 flex-1 min-w-0">
                                                    <label htmlFor="expiration" className="sr-only">Expiration
                                                        date</label>
                                                    <input
                                                        value={card.expiration}
                                                        onChange={(e) => handleUpdate(e)}
                                                        type="text" name="expiration"
                                                            pattern={'^(0[1-9]|1[0-2])\\/([0-9]{4}|[0-9]{2})$'}
                                                           id="expiration"
                                                           className="border focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                                           placeholder="MM / YY"/>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <label htmlFor="cvc" className="sr-only">CVC</label>
                                                    <input
                                                        value={card.cvc}
                                                        onChange={(e) => handleUpdate(e)}
                                                        type="text" name="cvc" id="cvc"
                                                           className="border focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                                           placeholder="CVC"/>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset className="mt-6 bg-white">
                                        <legend className="block text-sm font-medium text-gray-700">Billing
                                            address
                                        </legend>
                                        <div className="mt-1 rounded-md shadow-sm -space-y-px">
                                            <div>
                                                <label htmlFor="country" className="sr-only">Country</label>
                                                <select
                                                    value={card.country}
                                                    onChange={(e) => handleUpdate(e)}
                                                    id="country" name="country"
                                                        className="border focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300">
                                                    <option selected>USA</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="zip" className="sr-only">Postal code</label>
                                                <input
                                                    value={card.zip}
                                                    onChange={(e) => handleUpdate(e)}
                                                    type="text" name="zip" id="zip"
                                                       className="border focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                                       placeholder="Zip Code"/>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button
                                onClick={() => handleSubmit()}
                                type="submit"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                                {buttonText}
                            </button>
                            <button onClick={() => callback()} type="button"
                                    className="mt-2 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default BillingModal;
