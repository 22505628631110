import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import AuthContext from "../../context/AuthContext";
import { CogOutline, InboxInOutline, TrashOutline, X } from "heroicons-react";
import {Link} from "react-router-dom";
import Button from "../../components/inputs/Button";
import SubmissionsTable from '../../components/SubmissionsTable';
import ActionPanel from "../../components/ActionPanel";
import DashboardContext from "../../context/DashboardContext";
import AlertBar from "../../components/AlertBar";

const FormPage = () => {

    const {state: auth} = useContext(AuthContext);
    const {dispatch} = useContext(DashboardContext);
    const params: any = useParams();
    const { formCustomId } = params;
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({} as any);
    const [displayDeleteFormModal, setDisplayDeleteFormModal] = useState(false);

    const fetchIndividualFormData = async () => {
        const res = await fetch(`/api/forms/${formCustomId}/settings`);
        const data = await res.json();
        setForm(data.payload.form);
        setLoading(false);
    };

    const handleDelete = async (formCustomId) => {
        const res = await fetch(`/api/forms/${formCustomId}/delete`, { method: 'POST' });
        const data = await res.json();
    }

    const handleDisplayDeleteFormModal = () => {
        setDisplayDeleteFormModal(!displayDeleteFormModal);
    };

    useEffect(() => {
        fetchIndividualFormData().catch(err => err);
    }, []);

    return form && form.submissionCount ? (
        <Fragment>
            <div className="w-full flex justify-end">
                <div className="inline-block flex-grow">
                    <div className={"inline-block align-middle"}>
                        <InboxInOutline className={"align-middle inline-block h-6 w-6 mr-2 text-gray-500"}/>
                    </div>
                    <h3 className={"align-middle font-bold inline-block text-2xl text-gray-500"}>{formCustomId}</h3>
                </div>
                <div className={'inline-flex justify-end py-3'}>
                    <Link to={`/dashboard/forms/${formCustomId}/settings`}>
                        <Button py={1.5} px={2.5} textSize={'xs'} light
                                icon={<CogOutline className={"text-right h-4 w-4 text-gray-400"}/>}
                                buttonText={"Settings"}/>
                    </Link>
                    <a onClick={() => handleDisplayDeleteFormModal()}>
                        <Button py={1.5} px={2.5} textSize={'xs'} color={displayDeleteFormModal ? 'indigo' : 'red'}
                                icon={displayDeleteFormModal ? <X className={"text-right h-4 w-4 text-white-400"}/> : <TrashOutline className={"text-right h-4 w-4 text-white-400"}/>}
                                buttonText={displayDeleteFormModal ? "Cancel" : "Delete"}/>
                    </a>
                </div>
            </div>
            <SubmissionsTable formCustomId={formCustomId} />
            { displayDeleteFormModal ? <ActionPanel entityName={form.formName} callback={(formCustomId) => handleDelete(formCustomId)} hideCallback={() => handleDisplayDeleteFormModal()} /> : null }
        </Fragment>
    ) : <AlertBar border color={'indigo'}>No form data</AlertBar>
};

export default FormPage;
