export enum Plan {
	PLAN_ONE = 'FREE',
	PLAN_ONE_DISPLAY = 'Free',
	PLAN_ONE_DESCRIPTION = 'Forever free! A trusty submission endpoint for your website, blog, or project.',
	PLAN_ONE_MONTHLY_PRICE = 0,
	PLAN_ONE_ANNUAL_PRICE = 0,
	PLAN_ONE_ENDPOINT_ALLOWANCE = 1,
	PLAN_ONE_MONTHLY_SUBMISSION_ALLOWANCE = 50,


	PLAN_TWO = 'PRO',
	PLAN_TWO_DISPLAY = 'Pro',
	PLAN_TWO_DESCRIPTION = 'More forms, less time spent worrying about them.',
	PLAN_TWO_MONTHLY_PRICE = 3,
	PLAN_TWO_ANNUAL_PRICE = 19,
	PLAN_TWO_ENDPOINT_ALLOWANCE = 10,
	PLAN_TWO_MONTHLY_SUBMISSION_ALLOWANCE = 500,


	PLAN_THREE = 'AGENCY',
	PLAN_THREE_DISPLAY = 'Agency',
	PLAN_THREE_DESCRIPTION = 'Stand apart from the competition with a robust data collection tool.',
	PLAN_THREE_MONTHLY_PRICE = 9,
	PLAN_THREE_ANNUAL_PRICE = 49,
	PLAN_THREE_ENDPOINT_ALLOWANCE = 100,
	PLAN_THREE_MONTHLY_SUBMISSION_ALLOWANCE = 5000,
}
