import React, { Fragment, useContext, useEffect, useState } from 'react';
import ButtonGroup from './ButtonGroup';
import AuthContext from '../context/AuthContext';
import moment from 'moment-timezone';
import Loader from './common/Loader';
import {
	ArrowNarrowRight,
	EyeOutline,
	FolderOutline,
	InboxInOutline,
	Plus,
	TrendingDown,
	TrendingUp
} from 'heroicons-react';
import { Entity } from '../constants';
import IntervalContext from '../context/IntervalContext';
import { Interval } from '../constants';
import { Link } from 'react-router-dom';

const StatBar = ({ callback, className }) => {
	const { state: auth } = useContext(AuthContext);
	const { state, dispatch } = useContext(IntervalContext);
	const { interval } = state;
	const [userTimezone, setUserTimezone] = useState(auth.timezone);
	const [loading, setLoading] = useState(true);
	const [totalSubmissionCountAllTime, setTotalSubmissionCountAllTime] = useState(0);
	const [totalFormCountAllTime, setTotalFormCountAllTime] = useState(0);
	const [totalSubmissionCountForInterval, setTotalSubmissionCountForInterval] = useState(0);
	const [totalSubmissionCountForPreviousInterval, setTotalSubmissionCountForPreviousInterval] = useState(0);
	const [totalFormCountForInterval, setTotalFormCountForInterval] = useState(0);
	const [totalFormCountForPreviousInterval, setTotalFormCountForPreviousInterval] = useState(0);

	useEffect(() => {
		setUserTimezone(auth.timezone);
		setLoading(false);
	}, [auth.timezone]);

	useEffect(() => {
		fetchTotalForEntityAllTime(Entity.SUBMISSIONS).catch(err => err);
		fetchTotalForEntityAllTime(Entity.FORMS).catch(err => err);
	}, []);

	useEffect(() => {
		fetchTimeSeriesForEntity(Entity.SUBMISSIONS).catch(err => err);
		fetchTimeSeriesForEntity(Entity.FORMS).catch(err => err);
	}, [interval]);

	const fetchTotalForEntityAllTime = async (entity) => {
		const res = await fetch(`/api/${entity.toLowerCase()}?count=true`);
		const data = await res.json();
		if (entity === Entity.SUBMISSIONS) {
			setTotalSubmissionCountAllTime(data.payload.count);
		} else if (entity === Entity.FORMS) {
			setTotalFormCountAllTime(data.payload.count);
		}
	};

	const fetchTimeSeriesForEntity = async (entity, lookback = false) => {
		let apiUrl = lookback ?
			`/api/${entity.toLowerCase()}/time-series?interval=${interval}&count=true&lookback=${lookback}`
			: `/api/${entity.toLowerCase()}/time-series?interval=${interval}&count=true`;
		const res = await fetch(apiUrl);
		const data = await res.json();
		if (entity === Entity.SUBMISSIONS && !lookback) {
			setTotalSubmissionCountForInterval(data.payload.count);
		} else if (entity === Entity.FORMS && !lookback) {
			setTotalFormCountForInterval(data.payload.count);
		} else if (entity === Entity.SUBMISSIONS && lookback) {
			setTotalSubmissionCountForPreviousInterval(data.payload.count);
		} else if (entity === Entity.FORMS && lookback) {
			setTotalFormCountForPreviousInterval(data.payload.count);
		}
	};

	const calculateDifferenceOverTimeSeries = (totalAllTime, totalForTimeSeries) => {
		let result = Math.abs(((totalAllTime - (totalAllTime - totalForTimeSeries)) / (totalAllTime - totalForTimeSeries)) * 100).toFixed(2);
		return parseFloat(result) === (Infinity || NaN) ? 0 : parseFloat(result);
	};

	const generateIntervalNounsFromAdverbs = (interval) => {
		switch (interval) {
			case Interval.DAILY:
				return Interval.DAY.toLowerCase();
			case Interval.WEEKLY:
				return Interval.WEEK.toLowerCase();
			case Interval.MONTHLY:
				return Interval.MONTH.toLowerCase();
			case Interval.YEARLY:
				return Interval.YEAR.toLowerCase();
			default:
				break;
		}
	};

	const submissionTimeSeriesComparison = calculateDifferenceOverTimeSeries(totalSubmissionCountAllTime, totalSubmissionCountForInterval);
	const formTimeSeriesComparison = calculateDifferenceOverTimeSeries(totalFormCountAllTime, totalFormCountForInterval);
	const averageSubmissionsPerFormForTimeSeries = parseFloat((totalSubmissionCountForInterval / totalFormCountAllTime).toFixed(2));
	const averageSubmissionsPerFormAllTime = parseFloat((totalSubmissionCountAllTime / totalFormCountAllTime).toFixed(2));
	const averageSubmissionsPerFormComparison = calculateDifferenceOverTimeSeries(totalFormCountAllTime, averageSubmissionsPerFormForTimeSeries);

	const stats = [
		{
			metric: 'New Submissions',
			value: totalSubmissionCountForInterval || 0,
			intervalText: interval === Interval.DAILY ? 'today' : `this ${generateIntervalNounsFromAdverbs(interval)}`,
			totalText: `out of ${totalSubmissionCountAllTime || 0} all-time`,
			timeSeriesComparison: submissionTimeSeriesComparison,
			increase: submissionTimeSeriesComparison > 0,
			icon: <InboxInOutline className={'text-gray-400'} />
		},
		{
			metric: 'New Forms ',
			value: totalFormCountForInterval || 0,
			intervalText: interval === Interval.DAILY ? 'today' : `this ${generateIntervalNounsFromAdverbs(interval)}`,
			totalText: `out of ${totalFormCountAllTime || 0} all-time`,
			timeSeriesComparison: formTimeSeriesComparison,
			increase: formTimeSeriesComparison > 0,
			icon: <FolderOutline className={'text-gray-400'} />
		},
		{
			metric: 'Avg. Submissions Per Form',
			value: averageSubmissionsPerFormForTimeSeries || 0,
			intervalText: interval === Interval.DAILY ? 'today' : `this ${generateIntervalNounsFromAdverbs(interval)}`,
			totalText: `out of ${averageSubmissionsPerFormAllTime || 0} all-time`,
			timeSeriesComparison: averageSubmissionsPerFormComparison,
			increase: averageSubmissionsPerFormComparison > 0 ,
			icon: <EyeOutline className={'text-gray-400'} />
		}
	];

	const renderStatsBar = stats => {
		return stats.map((stat: any, key: number) => {
			return (
				<div key={key} className='border-t border-gray-200 md:border-0 md:border-l'>
					<div className='px-4 py-5 sm:p-6'>
						<dl>
							<dt className='text-base leading-6 font-semibold text-gray-900'>{stat.metric}</dt>
							<dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
								<div className='flex items-baseline text-2xl leading-8 font-semibold text-green-600'>
									{stat.value}
									<span
										className='ml-2 text-sm leading-5 font-medium text-gray-500'> {stat.intervalText} </span>
								</div>
								{stat.increase && stat.timeSeriesComparison ? (
									<Fragment>
										<div
											key={key}
											className='inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0'
										>
											<TrendingUp
												className={'-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'} />
											<span
												className='sr-only'> Increased by </span> {stat.timeSeriesComparison + '%'}
										</div>
									</Fragment>
								) : (
									<Fragment>
										<div
											key={key}
											className={`${!stat.timeSeriesComparison ? 'bg-gray-100 text-gray-800' : ' bg-red-100 text-red-800 '
											} inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 md:mt-2 lg:mt-0`}
										>
											{
												!stat.timeSeriesComparison ? (
													<ArrowNarrowRight
														className={`${
															!stat.timeSeriesComparison ? 'text-gray-500' : 'text-red-500'
														} -ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5`}
													/>
												) : (
													<TrendingDown
														className={'-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'} />
												)}
											<span
												className='sr-only'> Decreased by</span> {stat.timeSeriesComparison ? stat.timeSeriesComparison + '%' : 'N/A'}
										</div>
									</Fragment>
								)}
							</dd>
						</dl>
						<div className='mt-1 text-sm leading-5 font-medium text-gray-500'> {stat.totalText} </div>
					</div>
				</div>
			);
		});
	};

	return !loading && userTimezone ? (
		<div className={`${className} transition ease-in duration-100`}>
			<h3 className='text-lg leading-6 font-medium text-gray-900'>
				<div className="flex justify-between flex-wrap-reverse">
					<div className={'inline-block'}>
						<ButtonGroup />
					</div>
					<div className="inline-block xs:my-5">
						<Link to={'/dashboard/forms/new'}>
							<button type="button"
									className="mb-5 inline-flex items-center px-2.5 py-1 border border-transparent text-xs font-medium rounded-lg shadow text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 transition duration-150">
								<Plus className={'w-4 h-4'} />
								<span>New Form</span>
							</button>
						</Link>
					</div>
				</div>
			</h3>
			<div
				className='mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3'>{renderStatsBar(stats)}</div>
		</div>
	) : null;
};

export default StatBar;
