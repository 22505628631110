import { LOADING } from "../actions";

export const setLoadingTrue = (dispatch) => {
    dispatch({ type: LOADING, payload: true });
}

export const setLoadingFalse = (dispatch, delay = 0) => {
    setTimeout(() => {
        dispatch({ type: LOADING, payload: false });
    }, delay)
}
