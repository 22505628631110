import React, { useEffect, useState } from 'react';
import { Eye, Trash } from "heroicons-react";
import MultiSelectDropdown from "./MultiSelectDropdown";

const DemoSubmissionsTable = () => {
    const [currentlySelectedSubmissions, setCurrentlySelectedSubmissions] = useState([]);
    const [dislaySubmissionModal, setDisplaySubmissionModal] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [tableHeadersWithDisplayStatus, setTableHeadersWithDisplayStatus] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    let submissionsPayload = {
        "status": 200,
        "payload": {
            "total": 5,
            "submissions": [{
                "formCustomId": "faang",
                "formName": "FAANG Product Launch",
                "data": { "name": "Elon Musk", "message": "Great product!" }
            }, {
                "formCustomId": "faang",
                "formName": "FAANG Product Launch",
                "data": { "name": "Mark Cuban", "message": "Product looks good. Can we add functionality for..." }
            }, {
                "formCustomId": "faang",
                "formName": "FAANG Product Launch",
                "data": { "name": "Gary Vaynerchuk", "message": "Total gamechanger you've got here.'" }
            }, {
                "formCustomId": "faang",
                "formName": "FAANG Product Launch",
                "data": {
                    "name": "Jeff Bezos",
                    "message": "Have you thought of expanding this to horizontal markets?"
                }
            }, {
                "formCustomId": "faang",
                "formName": "FAANG Product Launch",
                "data": {
                    "name": "Larry Page",
                    "message": "Wow! User-friendly indeed. Great product."
                }
            }]
        }
    };

    const { submissions } = submissionsPayload.payload;

    useEffect(() => {
        generateTableHeaders(submissions);
    }, [])

    useEffect(() => {
        setCurrentPage(currentPage + 1);
    }, []);

    const handleModalDisplayClick = (event: any) => {
        const submissionId = event.target.parentElement.dataset.submission || event.target.parentElement.parentElement.dataset.submission;
    };

    const handleToggleSelectAllSubmissionsOnPage = (event: any) => {
        if (event.target.checked) {
            const temp = [];
            submissions.map((submission: any) => {
                return temp.push({
                    submissionId: submission._id,
                    formCustomId: submission.formCustomId
                });
            });
            setCurrentlySelectedSubmissions(temp);
        } else {
            setCurrentlySelectedSubmissions([]);
        }
    };

    const handleToggleSelectSingleSubmission = (event: any, submissionId: any, formCustomId: any) => {
        let temp = [...currentlySelectedSubmissions];
        if (event.target.checked) {
            temp.push({ submissionId, formCustomId });
        } else {
            temp = temp.filter(item => {
                return item.submissionId !== submissionId;
            });
        }
        setCurrentlySelectedSubmissions(temp);
    };

    const handleDeleteSubmissions = async (submissionsToBeDeletedArr: any) => {
        console.log('Join Sendpoint.io if you want to *really* delete submissions ;)')
    };

    const generateTableHeaders = (submissions: any) => {
        const allTableHeaders: any = [];
        submissions.map((submission: any) => {
            return submission.data ? allTableHeaders.push(...Object.keys(submission.data)) : allTableHeaders.push('');
        });
        setTableHeaders(Array.from(new Set(allTableHeaders)));

        let uniqueTableHeaders = new Set(allTableHeaders);
        let uniqueTableHeadersArray = Array.from(uniqueTableHeaders);
        let tempTableHeaderWithStatus = [];

        uniqueTableHeadersArray.map(tableHeader => {
            return tempTableHeaderWithStatus.push({ name: tableHeader, display: true });
        });
        setTableHeadersWithDisplayStatus(tempTableHeaderWithStatus as never[]);
    };

    const renderTableHeaders = (tableHeaders: any) => {
        return tableHeaders.map((tableHeader: any, key: number) => {
            if (tableHeader.name === '') {
                return null;
            } else {
                return tableHeader.display ? (
                    <th key={key}
                        className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                        {tableHeader.name}
                    </th>
                ) : null;
            }
        });
    };

    const tableStyles = {
        tableData: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: 225
        }
    };

    const renderIndividualSubmissionColumns = (submission: any) => {
        return tableHeadersWithDisplayStatus.map((column: any, key: number) => {
            if (!column.name) return null;
            else if (column.display) {
                return submission.data && submission.data[column.name] ? (
                    <td style={tableStyles.tableData} key={key}
                        className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                        {submission.data[column.name]}
                    </td>
                ) : (
                    <td key={key}
                        className='opacity-50 px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'/>
                );
            } else return null;
        });
    };

    const renderSubmissions = (submissions: any) => {
        return submissions.map((submission: any, key: number) => {
            return (
                <tr data-submission-id={submission._id} data-submission={key} data-submission-key={key} key={key}>
                    <td className={'border-b border-gray-200 '}>
                        <input
                            data-submission-id={submission._id}
                            data-submission-key={key}
                            id={`submission-checkbox-${key}`}
                            onChange={e => handleToggleSelectSingleSubmission(e, submission._id, submission.formCustomId)}
                            checked={currentlySelectedSubmissions.some(checkbox => checkbox.submissionId === submission._id)}
                            type={'checkbox'}
                            className={'bg-gray-50 mx-3 my-3 form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out'}
                        />
                    </td>
                    <td key={key}
                        className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                        {submission.formCustomId || 'Form'}
                    </td>
                    {renderIndividualSubmissionColumns(submission)}
                    <td className='w-3 px-6 py-4 justify-end whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium'>
                        <a data-submission={key} key={key} onClick={() => {}}
                           className='cursor-pointer text-gray-300 hover:text-gray-400'>
                            <Eye data-submission={key} className={'h-5 w-5'}/>
                        </a>
                    </td>
                </tr>
            );
        });
    };

    return submissions ? (
        <div
            className={`${
                submissions.length > 0 ? 'transition-opacity opacity-100 duration-500 ease-in-out' : 'opacity-0 transition-opacity duration-500 ease-in-out'
            } flex flex-col`}
        >
            <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
                <div
                    className='align-middle inline-block min-w-full shadow overflow-hidden rounded-lg border-b border-gray-200'>
                    <div className='bg-white border-b border-gray-200 px-6'>
                        <div className='flex flex-wrap sm:flex-no-wrap'>
                            <div>
                                <div>
                                    {currentlySelectedSubmissions.length > 0 ? (
                                        <div className={'space-y-1 w-auto inline-block'}>
											<span className='inline-flex rounded-md shadow-sm'>
												<button
                                                    onClick={() => handleDeleteSubmissions(currentlySelectedSubmissions)}
                                                    type='button'
                                                    className='space-y-1 my-3 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:red active:bg-red-600 transition ease-in-out duration-150'
                                                >
													<Trash className={'h-4 w-4 mr-2'}/>
                                                    {currentlySelectedSubmissions.length > 1 ? `Delete ${currentlySelectedSubmissions.length} Submissions` : `Delete Submission`}
												</button>
											</span>
                                        </div>
                                    ) : (
                                        <MultiSelectDropdown
                                            tableHeadersWithDisplayStatus={tableHeadersWithDisplayStatus}
                                            setTableHeadersWithDisplayStatus={setTableHeadersWithDisplayStatus}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className='min-w-full'>
                        <thead>
                        <tr>
                            <th className={'border-b border-gray-200 bg-gray-50 w-3'}>
                                <input
                                    onClick={e => handleToggleSelectAllSubmissionsOnPage(e)}
                                    id={`submission-checkbox-selectall`}
                                    type='checkbox'
                                    className='mx-3 my-3 border-b border-gray-200 bg-gray-50 form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out'
                                />
                            </th>
                            <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                                Form ID
                            </th>
                            {renderTableHeaders(tableHeadersWithDisplayStatus)}
                            <th className='px-6 py-3 border-b border-gray-200 bg-gray-50'/>
                        </tr>
                        </thead>
                        <tbody className='bg-white'>{renderSubmissions(submissions)}</tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : null
};

export default DemoSubmissionsTable;
