import React, { useContext } from 'react';
import DashboardChart from "../../components/DashboardChart";
import { Interval } from "../../constants";
import IntervalContext from "../../context/IntervalContext";
import SubmissionsTable from "../../components/SubmissionsTable";

const DashboardHome = () => {
    const { state } = useContext(IntervalContext);
    const { interval } = state;

    const generateIntervalDisplayText = (interval) => {
        if (interval === Interval.DAILY) {
            return "Daily";
        } else if (interval === Interval.WEEKLY) {
            return "Weekly";
        } else if (interval === Interval.MONTHLY) {
            return "Monthly";
        } else if (interval === Interval.YEARLY) {
            return "Yearly";
        }
    };

    return (
        <div className="py-4">
            <div>
                <div className="max-w-7xl my-4">
                    <h1 className="text-2xl font-semibold text-gray-900">{`${generateIntervalDisplayText(interval)} Submissions`}</h1>
                </div>
                <DashboardChart/>
                <div className="max-w-7xl my-4">
                    <h1 className="mt-5 text-2xl font-semibold text-gray-900">{`Latest Submissions`}</h1>
                </div>
                <SubmissionsTable limit={5}/>
            </div>
        </div>
    )
};

export default DashboardHome;
