import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuAlt4 } from 'heroicons-react';
import { IgnoredTableHeaders } from "../constants";

const MultiSelectDropdown = ({
	tableHeadersWithDisplayStatus,
	setTableHeadersWithDisplayStatus
}: {
	tableHeadersWithDisplayStatus: any;
	setTableHeadersWithDisplayStatus: any;
}) => {
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [displayedHeaders, setDisplayedHeaders] = useState([]);
	const dropDownRef = useRef();

	useEffect(() => {
		setDisplayedHeaders(tableHeadersWithDisplayStatus);
		localStorage.setItem('sendpoint__submission_fields', displayedHeaders.toString());
	}, [tableHeadersWithDisplayStatus]);

	useEffect(() => {
		const handleClickEvent = event => {
			// @ts-ignore
			if (dropDownRef.current && !event.target.classList.contains('tableheader-dropdown')) {
				setToggleDropdown(false);
			}
		};
		document.addEventListener('mousedown', handleClickEvent);
		return () => {
			document.removeEventListener('mousedown', handleClickEvent);
		};
	}, [dropDownRef]);

	const handleToggleDisplayField = (event: any) => {
		const target = event.target.textContent;
		let temp = displayedHeaders.map((header: any) => {
			if (header.name === target) {
				return { name: header.name, display: !header.display };
			}
			return header;
		});
		setTableHeadersWithDisplayStatus(temp);
	};

	const renderTableHeaderList = (displayedHeaders: any) => {
		return displayedHeaders.map((tableHeader: any, key: number) => {
			if (IgnoredTableHeaders.includes(tableHeader.name)) return;
			return tableHeader.display ? (
				<li
					data-tableheader={key + 1}
					onClick={(e: any) => handleToggleDisplayField(e)}
					key={key}
					id={`listbox-item-${key}`}
					role='option'
					className='tableheader-dropdown text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9'
				>
					<div className='tableheader-dropdown flex items-center space-x-3'>
						<span aria-label='Online' className='tableheader-dropdown bg-indigo-500 flex-shrink-0 inline-block h-2 w-2 rounded-full' />
						<span className='tableheader-dropdown font-semibold block truncate'>{tableHeader.name}</span>
					</div>
					<span className='tableheader-dropdown absolute inset-y-0 right-0 flex items-center px-4'>
						<svg className='tableheader-dropdown h-3 w-3' viewBox='0 0 20 20' fill='currentColor'>
							<path
								fillRule='evenodd'
								d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
								clipRule='evenodd'
							/>
						</svg>
					</span>
				</li>
			) : (
				<li
					data-tableheader={key + 1}
					onClick={(e: any) => handleToggleDisplayField(e)}
					key={key}
					id={`listbox-item-${key}`}
					role='option'
					className='tableheader-dropdown text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9'
				>
					<div className='tableheader-dropdown flex items-center space-x-3'>
						<span aria-label='Not Online' className='tableheader-dropdown bg-gray-200 flex-shrink-0 inline-block h-2 w-2 rounded-full' />
						<span className='tableheader-dropdown font-normal block truncate'>{tableHeader.name}</span>
					</div>
				</li>
			);
		});
	};

	return (
		<div onClick={() => setToggleDropdown(true)} className='space-y-1 my-3 w-auto float-left tableheader-dropdown'>
			<div className='z-0 relative tableheader-dropdown'>
				<span className='inline-flex rounded-md shadow-sm tableheader-dropdown'>
					<button
						type='button'
						className='tableheader-dropdown inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:border-green-600 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150'
					>
						<Menu className={'h-3 w-3 mr-2 tableheader-dropdown'} /> Toggle Fields
					</button>
				</span>
				{toggleDropdown ? (
					<div ref={dropDownRef} className='z-50 tableheader-dropdown absolute mt-1 rounded-md bg-white shadow-lg'>
						<ul
							data-tableheader={0}
							tabIndex={-1}
							role='listbox'
							aria-labelledby='listbox-label'
							aria-activedescendant={`listbox-item`}
							className='tableheader-dropdown rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5'
						>
							{renderTableHeaderList(displayedHeaders)}
						</ul>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default MultiSelectDropdown;
