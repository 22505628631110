import React, { useContext, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, X } from 'heroicons-react';
import DashboardContext from '../context/DashboardContext';
import { Navigation } from '../constants';
import { SUBMISSION_DISPLAY_MODAL, SUBMISSION_NEXT, SUBMISSION_PREV } from "../actions";

const SubmissionModal = ({ children, display, id }: { children?: any; display?: boolean; id?: string }) => {
	const { state: dashboard, dispatch } = useContext(DashboardContext);
	const modalRef: any = useRef();

	useEffect(() => {
		const handleClickEvent = event => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				dispatch({ type: SUBMISSION_DISPLAY_MODAL, payload: false });
			}
		};
		document.addEventListener('mousedown', handleClickEvent);
		return () => {
			document.removeEventListener('mousedown', handleClickEvent);
		};
	}, [modalRef]);

	const handleClick = (direction: any) => {
		if (direction === Navigation.PREVIOUS && dashboard.currentlyDisplayedSubmissionId) {
			dispatch({ type: SUBMISSION_PREV, payload: dashboard.currentlyDisplayedSubmissionId });
		} else if (direction === Navigation.NEXT) {
			dispatch({ type: SUBMISSION_NEXT, payload: dashboard.currentlyDisplayedSubmissionId });
		}
	};

	return dashboard.displaySubmissionModal ? (
		<div id={id} className='fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center'>
			<div className={`${dashboard.displaySubmissionModal ? 'opacity-75 ease-in duration-200 ' : 'opacity-0 ease-out duration-300 '} fixed inset-0 transition-opacity`}>
				<div className='absolute inset-0 bg-gray-500 opacity-75' />
			</div>

			<div
				ref={modalRef}
				className={`${
					dashboard.displaySubmissionModal
						? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100 '
						: 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 '
				} bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-md transform transition-all sm:max-w-2xl sm:w-full sm:p-6`}
				role='dialog'
				aria-modal='true'
				aria-labelledby='modal-headline'
			>
				<div style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: SUBMISSION_DISPLAY_MODAL, payload: false })} className={'float-right block'}>
					<X className={'h-5 w-5 text-gray-400'} />
				</div>
				<div>{children}</div>
				<div className='mt-4 flex-1 flex justify-between sm:justify-end'>
					<a
						onClick={() => handleClick(Navigation.PREVIOUS)}
						className='cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'
					>
						<ChevronLeft className={'text-gray-400'} />
					</a>
					<a
						onClick={() => handleClick(Navigation.NEXT)}
						className='cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'
					>
						<ChevronRight className={'text-gray-400'} />
					</a>
				</div>
			</div>
		</div>
	) : null;
};

export default SubmissionModal;
