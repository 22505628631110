export enum Interval {
    ALL = 'ALL',
    DAY = 'DAY',
    DAY_DISPLAY = 'Day',
    DAILY = 'DAILY',
    WEEK = 'WEEK',
    WEEK_DISPLAY = 'Week',
    WEEKLY = 'WEEKLY',
    MONTH = 'MONTH',
    MONTH_DISPLAY = 'Month',
    MONTHLY = 'MONTHLY',
    MONTHLY_DISPLAY = 'Monthly',
    YEAR = 'YEAR',
    YEAR_DISPLAY = 'Year',
    YEARLY = 'YEARLY',
    ANNUAL = 'ANNUAL',
    ANNUAL_DISPLAY = 'Annual',
    ANNUALLY = 'ANNUALLY',
    ANNUALLY_DISPLAY = 'Annually',
    LIFETIME = 'LIFETIME',
    LIFETIME_DISPLAY = 'Lifetime'
}

export enum Slice {
    LOOKBACK = 'LOOKBACK',
    CURRENT = 'CURRENT'
}
