import React, { Fragment } from 'react';

const InfoBar = () => {
	return (
		<div className='w-full bg-gradient-to-r from-indigo-500 to-indigo-600 pt-12 sm:pt-16'>
			<div className='max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div className='max-w-4xl mx-auto text-center'>
					<h2 className='text-2xl leading-9 font-extrabold text-white sm:text-3xl sm:leading-10'>Trusted by makers, developers, and growth-hackers</h2>
					<p className='mt-3 text-xl leading-7 text-white sm:mt-4'>Contact form management for all of your outreach needs.</p>
				</div>
			</div>
			<div className='mt-10 pb-12 bg-white sm:pb-16'>
				<div className='relative'>
					<div className='absolute inset-0 h-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600' />
					<div className='relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
						<div className='max-w-4xl mx-auto'>
							<dl className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3'>
								{/*<div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r'>*/}
								{/*	<dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500' id='item-1'>*/}
								{/*		Secure*/}
								{/*	</dt>*/}
								{/*	<dd className='order-1 text-5xl leading-none font-extrabold text-gray-700' aria-describedby='item-1'>*/}
								{/*		100%*/}
								{/*	</dd>*/}
								{/*</div>*/}
								{/*<div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>*/}
								{/*	<dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>Support</dt>*/}
								{/*	<dd className='order-1 text-5xl leading-none font-extrabold text-gray-700'>24/7</dd>*/}
								{/*</div>*/}
								{/*<div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>*/}
								{/*	<dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>Submissions</dt>*/}
								{/*	<dd className='order-1 text-5xl leading-none font-extrabold text-gray-700'>100k+</dd>*/}
								{/*</div>*/}
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InfoBar;
