import React, { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import Avatar from './Avatar';
import AuthContext from '../context/AuthContext';
import Input from './inputs/Input';
import Facebook from '../assets/img/facebook-login.svg';
import Github from '../assets/img/github-login.svg';
import Google from '../assets/img/google-login.svg';
import Local from '../assets/img/local-login.svg';
import Tag from './Tag';
import { DuplicateOutline, KeyOutline, XCircleOutline } from 'heroicons-react';
import { emitToastNotification } from '../utils/notificationUtils';
import NotificationContext from '../context/NotificationContext';
import { NotificationType } from '../constants';
import { INotification } from '../types/notification';
import { useHistory } from 'react-router-dom';
import AlertBar from './AlertBar';

const ProfileSection = () => {
	const { state: auth } = useContext(AuthContext);
	const { dispatch } = useContext(NotificationContext);
	const [name, setName] = useState(auth.user.name);
	const [company, setCompany] = useState(auth.user.company);
	const [email, setEmail] = useState(auth.user.email);
	const [username, setUsername] = useState(auth.user.username);
	const [phone, setPhone] = useState(auth.user.phone);
	const [avatarUrl, setAvatarUrl] = useState(auth.user.avatar);
	const [apiKey, setApiKey] = useState(auth.user.apiKey);
	const [displayConfirmRevokeApiKey, setDisplayConfirmRevokeApiKey] = useState(false);
	const [displayConfirmGenerateApiKey, setDisplayConfirmGenerateApiKey] = useState(false);
	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = {
			name,
			email,
			company,
			username,
			avatarUrl
		};

		const res = await fetch('/api/user/profile/update', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		});

	};

	const handleRevokeApiKey = async () => {
		const res = await fetch('/api/user/api-key/revoke', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		setApiKey(null);
		history.go(0);
	};

	const handleGenerateApiKey = async () => {
		const res = await fetch('/api/user/api-key', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		const data = await res.json();
		setApiKey(data);
		history.go(0);
	};

	const handleCopyApiKey = async () => {
		if (apiKey) {
			await navigator.clipboard.writeText(apiKey);
			const notification = {
				title: 'API Key Copied',
				message: 'API Key copied to clipboard',
				type: NotificationType.SUCCESS
			} as INotification;
			emitToastNotification(dispatch, notification);
		} else {
			const notification = {
				title: 'No API Key Found',
				message: 'User does not have API key. Plan upgrade required.',
				type: NotificationType.ERROR
			} as INotification;
			emitToastNotification(dispatch, notification);
		}
	};

	const getAuthStrategyImage = (authStrategy) => {
		switch (authStrategy) {
			case 'google':
				return Google;
			case 'facebook':
				return Facebook;
			case 'github':
				return Github;
			default:
				return Local;
		}
	};

	return auth && auth.user ? (
		<Fragment>
			<div>
				<div className='md:grid md:grid-cols-3 md:gap-6'>
					<div className='mt-5 md:mt-0 md:col-span-1'>
						<div className='shadow sm:rounded-md sm:overflow-hidden'>
							<div className='px-4 py-5 bg-white sm:p-6 justify-items-center grid'>
								<div className='mt-2 items-center mx-auto'>
									<Avatar className={'ring-4 ring-current ring-opacity-50'}
											avatarUrl={auth.user.avatar} size={14} />
								</div>
								<div className={'mt-4'}>
									<p className={'font-bold text-gray-900 text-sm'}>{auth.user.name}</p>
								</div>
								<div className={'mt-2 text-gray-700 text-sm'}>
									<p>{auth.user.email}</p>
								</div>
								<div className={'mt-2 text-gray-700 text-sm'}>
									<Tag color={'green'} text={auth.user.username} />
								</div>
								<div className={'mt-2 text-gray-700'}>
									<p className={'inline-block text-xs font-medium mr-2'}>Registered via: </p>
									<img className={'w-4 h-auto inline-block opacity-50'}
										 src={getAuthStrategyImage(auth.user.authStrategy)} />
								</div>
							</div>
						</div>
						<div className='shadow sm:rounded-md sm:overflow-hidden mt-4'>
							<div className='px-4 py-5 bg-white sm:p-6 grid'>
								<div>
									<label htmlFor="account_number" className="block text-sm font-medium text-gray-700">
										API Key
									</label>
									{auth.user.apiKey ? (
										<Fragment>
											<div onClick={() => handleCopyApiKey()}
												 className="mt-1 relative rounded-md shadow-sm">
												<input
													onClick={() => handleCopyApiKey()}
													type="text"
													name="apiKey"
													disabled
													className="cursor-pointer border bg-gray-200 text-gray-400 ring-gray-500 ring block w-full pr-10 sm:text-xs border-gray-300 rounded-md"
													placeholder={auth.user.apiKey} />
												<div
													className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none cursor-pointer">
													<DuplicateOutline
														className={'h-5 w-5 text-gray-400 cursor-pointer'} />
												</div>
											</div>
											<div className={'mt-2 flex justify-between gap-2'}>
												{!displayConfirmGenerateApiKey ? (<button type="button"
																						  onClick={() => {
																							  setDisplayConfirmGenerateApiKey(true);
																							  setDisplayConfirmRevokeApiKey(false);
																						  }}
																						  className="flex-1 inline-block items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
													<KeyOutline className={'h-4 w-4 mr-2 inline-block'} />
													Generate API Key
												</button>) : (
													<button type="button" onClick={() => handleGenerateApiKey()}
															className="flex-1 inline-block items-center px-2.5 py-1.5 border border-green-300 shadow-sm text-xs font-medium rounded text-green-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
														<KeyOutline className={'h-4 w-4 mr-2 inline-block'} />Confirm
													</button>)}
												{!displayConfirmRevokeApiKey ? (<button type="button"
																						onClick={() => {
																							setDisplayConfirmRevokeApiKey(true);
																							setDisplayConfirmGenerateApiKey(false);
																						}}
																						className="flex-1 inline-block items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
													<XCircleOutline className={'h-4 w-4 mr-2 inline-block'} />
													Revoke API Key
												</button>) : (
													<button type="button" onClick={() => handleRevokeApiKey()}
															className="flex-1 inline-block items-center px-2.5 py-1.5 border border-red-300 shadow-sm text-xs font-medium rounded text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
														<XCircleOutline className={'h-4 w-4 mr-2 inline-block'} />Revoke
													</button>)
												}
											</div>
										</Fragment>
									) : (<button type="button"
												 onClick={() => handleGenerateApiKey()}
												 className="mt-4 w-full flex-1 inline-block items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											<KeyOutline className={'h-4 w-4 mr-2 inline-block'} />
											Generate API Key
										</button>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className='mt-5 md:mt-0 md:col-span-2'>
						<form>
							<div className='shadow sm:rounded-md sm:overflow-hidden'>
								<div className='px-4 py-5 bg-white sm:p-6'>
									<div className='grid grid-cols-2 gap-6 mt-6'>
										<div>
											<label
												className="block text-sm font-medium leading-5 text-gray-700">Name</label>
											<input type={'text'}
												   name={'name'}
												   placeholder={'Name'}
												   value={name}
												   onChange={(e) => setName(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
										<div>
											<label
												className="block text-sm font-medium leading-5 text-gray-700">Company</label>
											<input type={'text'}
												   name={'company'}
												   placeholder={'Company'}
												   value={company}
												   onChange={(e) => setCompany(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
									</div>
									<div className='grid grid-cols-2 gap-6 mt-6'>
										<div>
											<label
												className="block text-sm font-medium leading-5 text-gray-700">Email</label>
											<input type={'text'}
												   name={'email'}
												   placeholder={'Email'}
												   value={email}
												   onChange={(e) => setEmail(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
										<div>
											<label
												className="block text-sm font-medium leading-5 text-gray-700">Username</label>
											<input type={'text'}
												   name={'username'}
												   placeholder={'Username'}
												   value={username}
												   onChange={(e) => setUsername(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
									</div>
									<div className='grid grid-cols-2 gap-6 mt-6'>
										<div>
											<label
												className="block text-sm font-medium leading-5 text-gray-700">Phone</label>
											<input type={'text'}
												   name={'phone'}
												   placeholder={'Phone'}
												   value={phone}
												   onChange={(e) => setPhone(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
										<div>
											<label className="block text-sm font-medium leading-5 text-gray-700">Avatar
												URL</label>
											<input type={'text'}
												   name={'avatarUrl'}
												   placeholder={'Avatar URL'}
												   value={avatarUrl}
												   onChange={(e) => setAvatarUrl(e.target.value)}
												   className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
											/>
										</div>
									</div>
								</div>
								<div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
									<button
										type={'button'}
										onClick={(e) => handleSubmit(e)}
										className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										Save
									</button>
								</div>
							</div>
						</form>
					</div>
					{/* TODO: enable referral banner */}
					{/*<div className='mt-5 md:mt-0 md:col-span-3'>*/}
					{/*	<AlertBar border={true} color={'indigo'} detailsButton={true}>*/}
					{/*		Referral Program*/}
					{/*	</AlertBar>*/}
					{/*</div>*/}
				</div>
			</div>
		</Fragment>
	) : null;
};

export default ProfileSection;
