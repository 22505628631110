import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/css/styles.css';
import moment from 'moment-timezone';
import AppLayout from './layout/AppLayout';
import Home from './views/marketing/Home';
import Login from './views/marketing/Login';
import Register from './views/marketing/Register';
import DashboardLayout from './layout/DashboardLayout';
import Pricing from './views/marketing/Pricing';
import DashboardHome from './views/dashboard/DashboardHome';
import Forms from './views/dashboard/Forms';
import Submissions from './views/dashboard/Submissions';
import Contacts from './views/dashboard/Contacts';
import Calendar from './views/dashboard/Calendar';
import Reports from './views/dashboard/Reports';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './views/dashboard/Profile';
import AuthContext from './context/AuthContext';
import DashboardContext from './context/DashboardContext';
import NotificationContext from './context/NotificationContext';
import authReducer from './reducers/authReducer';
import dashboardReducer from './reducers/dashboardReducer';
import notificationReducer from './reducers/notificationReducer';

import combineReducers from './reducers';
import Loader from './components/common/Loader';
import Pages from './views/dashboard/Pages';
import Surveys from './views/dashboard/Surveys';
import FormPage from './views/dashboard/FormPage';
import FormSettings from './views/dashboard/FormSettings';
import FormNew from './views/dashboard/FormNew';
import Page from './views/pages/Page';
import PageNew from './views/dashboard/PageNew';
import PageLayout from './layout/PageLayout';
import SurveyLayout from './layout/SurveyLayout';
import Survey from './views/surveys/Survey';
import FormBuilder from './views/dashboard/FormBuilder';
import { Interval, Paginate } from "./constants";
import IntervalContext from "./context/IntervalContext";
import intervalReducer from "./reducers/intervalReducer";
import DashboardModernLayout from './layout/DashboardModernLayout';
import Features from './views/marketing/Features';
import Billing from './views/dashboard/Billing';
import Docs from './views/marketing/Docs';
import { setLoadingFalse } from "./utils/dashboardUtils";
import { USER_DETAILS, USER_LOGIN } from "./actions";
import AccountLocked from "./views/dashboard/AccountLocked";
import ThankYou from "./views/dashboard/ThankYou";
import Robot from "./views/dashboard/Robot";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./views/marketing/ForgotPassword";
import ResetPassword from "./views/marketing/ResetPassword";
import ConfirmEmail from './views/marketing/ConfirmEmail';
import ConfirmDevice from './views/marketing/ConfirmDevice';

moment.tz.setDefault('Etc/UTC');

interface IInterval {
    interval: Interval.DAILY | Interval.WEEKLY | Interval.MONTHLY | Interval.YEARLY
}

const initialAuthState = {
    authenticated: false,
    timezone: moment.tz.guess(),
    user: {}
};

const initialDashboardState = {
    loading: true,
    currentlyDisplayedSubmissionId: 0,
    currentSubmissionPage: Paginate.PAGE,
    submissionPageSize: Paginate.LIMIT,
    displaySubmissionModal: false
};

const initialNotificationState = {
    display: false,
    message: null
};

const intervalInitialState: IInterval = {
    interval: Interval.WEEKLY
}

const App = () => {
    const [state, dispatch] = React.useReducer(
        combineReducers({
            auth: authReducer,
            dashboard: dashboardReducer,
            notification: notificationReducer,
            interval: intervalReducer
        }),
        {
            auth: initialAuthState,
            dashboard: initialDashboardState,
            notification: initialNotificationState,
            interval: intervalInitialState
        }
    );

    const { auth, dashboard, notification, interval } = state;

    const fetchCurrentUser = async () => {
        const res = await fetch('/api/current-user');
        const data = await res.json();
        if (res.ok) {
            dispatch({ type: USER_LOGIN, payload: data });
            dispatch({ type: USER_DETAILS, payload: data });
        }
    };

    useEffect(() => {
        setLoadingFalse(dispatch, 1000);
    }, [auth.user.authenticated])

    useEffect(() => {
        if (!auth.user.authenticated) fetchCurrentUser().catch(err => err);
    }, []);

    const { authenticated } = auth;
    return (
        <AuthContext.Provider value={{ state: auth, dispatch }}>
            <NotificationContext.Provider value={{ state: notification, dispatch }}>
                <IntervalContext.Provider value={{ state: interval, dispatch }}>
                    <div className="App">
                        <Loader loading={dashboard.loading}/>
                        <Router>
                            <ScrollToTop/>
                            <Switch>
                                <Route path={'/dashboard'}>
                                    <DashboardContext.Provider value={{ state: dashboard, dispatch }}>
                                        <DashboardModernLayout auth={auth}>
                                            <Switch>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard'} component={DashboardHome}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/forms'} component={Forms}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/forms/new'}
                                                                component={FormNew}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/forms/:formCustomId'}
                                                                component={FormPage}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact
                                                                path={'/dashboard/forms/:formCustomId/form-builder'}
                                                                component={FormBuilder}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/inbox'}
                                                                component={Submissions}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/forms/:formCustomId/settings'}
                                                                component={FormSettings}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/docs'}
                                                                component={Docs}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/profile'}
                                                                component={Profile}/>
                                                <ProtectedRoute auth={auth}
                                                                authenticated={authenticated}
                                                                exact path={'/dashboard/billing'}
                                                                component={Billing}/>
                                            </Switch>
                                        </DashboardModernLayout>
                                    </DashboardContext.Provider>
                                </Route>

                                {/*<Route path={'/forms'}>*/}
                                {/*	<Route path={'/forms/:formCustomId'} component={FormBuilder} />*/}
                                {/*</Route>*/}
                                {/*<Route path={'/pages'}>*/}
                                {/*	<PageLayout>*/}
                                {/*		<Route path={'/pages/:pagecustomId'} component={Page} />*/}
                                {/*	</PageLayout>*/}
                                {/*</Route>*/}
                                {/*<Route path={'/surveys'}>*/}
                                {/*	<SurveyLayout>*/}
                                {/*		<Route path={'/surveys/:surveyId'} component={Survey} />*/}
                                {/*	</SurveyLayout>*/}
                                {/*</Route>*/}
                                <Route path={'/thank-you'} exact component={ThankYou}/>
                                <Route path={'/robot'} exact component={Robot}/>
                                <Route path={'/account-locked'} exact component={AccountLocked}/>
                                <Route>
                                    <AppLayout>
                                        <Switch>
                                            <Route exact path={'/'} component={Home}/>
                                            <Route exact path={'/login'} component={Login}/>
                                            <Route exact path={'/register'} component={Register}/>
                                            <Route exact path={'/forgot-password'} component={ForgotPassword}/>
                                            <Route exact path={'/reset-password'} component={ResetPassword}/>
                                            <Route exact path={'/confirm-email'} component={ConfirmEmail}/>
                                            <Route exact path={'/confirm-device'} component={ConfirmDevice}/>
                                            <Route exact path={'/features'} component={Features}/>
                                            <Route exact path={'/pricing'} component={Pricing}/>
                                            <Route exact path={'/docs'} component={Docs}/>
                                        </Switch>
                                    </AppLayout>
                                </Route>
                            </Switch>
                        </Router>
                    </div>
                </IntervalContext.Provider>
            </NotificationContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
