import React, { Fragment } from 'react';
import CodeSnippet from '../CodeSnippet';
import { Link } from 'react-router-dom';

const HeroAlt = () => {
	return (
		<Fragment>
			<div className="bg-white pb-8 sm:pb-12 lg:pb-12">
				<div className="overflow-hidden lg:relative">
					<div
						className="text-center lg:text-left mx-auto md:text-left max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
						<div>
							<div className="my-5 md:my-20">
								<div className="mt-6">
									<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
										Contact Forms<br />for <span className={'highlight'}>Makers</span>
									</h1>
									<p className="mt-6 text-xl text-gray-500">
										Setting up forms from scratch can be annoying. Save yourself the hassle with an intuitive <b>Contact Form API</b>.
									</p>
								</div>
								<div className="mt-4 sm:mt-0">
									<Link to={'/register'}>
										<button type="submit"
												className="md:mx-0 xl:text-left mx-auto mt-4 block rounded-md border border-transparent px-5 py-3 bg-green-500 text-base font-medium text-white shadow hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:px-10">
											Setup a form in 60 seconds
										</button>
									</Link>
								</div>
								{/*<div className="mt-6">*/}
								{/*	<div className="inline-flex items-center divide-x divide-gray-300">*/}
								{/*		<div className="flex-shrink-0 flex pr-5">*/}
								{/*			/!*Heroicon name: star *!/*/}
								{/*			<svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"*/}
								{/*				 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
								{/*				<path*/}
								{/*					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />*/}
								{/*			</svg>*/}
								{/*			/!*Heroicon name: star *!/*/}
								{/*			<svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"*/}
								{/*				 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
								{/*				<path*/}
								{/*					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />*/}
								{/*			</svg>*/}
								{/*			/!*Heroicon name: star *!/*/}
								{/*			<svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"*/}
								{/*				 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
								{/*				<path*/}
								{/*					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />*/}
								{/*			</svg>*/}
								{/*			/!*Heroicon name: star *!/*/}
								{/*			<svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"*/}
								{/*				 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
								{/*				<path*/}
								{/*					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />*/}
								{/*			</svg>*/}
								{/*			/!*Heroicon name: star *!/*/}
								{/*			<svg className="h-5 w-text-yellow-400" xmlns="http://www.w3.org/2000/svg"*/}
								{/*				 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
								{/*				<path*/}
								{/*					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />*/}
								{/*			</svg>*/}
								{/*		</div>*/}
								{/*		<div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3"><span*/}
								{/*			className="font-medium text-gray-900">Rated 5 stars</span> by over <span*/}
								{/*			className="font-medium text-indigo-600">500 beta users</span></div>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						</div>
					</div>

					<div className="sm:mx-auto sm:max-w-3xl sm:px-6">
						<div
							className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-12 max-w-2xl">
							<div className="hidden sm:block">
								<div
									className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
								<svg className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404"
									 height="392" fill="none" viewBox="0 0 404 392">
									<defs>
										<pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20"
												 height="20" patternUnits="userSpaceOnUse">
											<rect x="0" y="0" width="4" height="4" className="text-gray-200"
												  fill="currentColor" />
										</pattern>
									</defs>
									<rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
								</svg>
							</div>
							<div className="m-4 lg:mt-20">
								<CodeSnippet
									className={'lg:-mr-10 w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:w-auto lg:max-w-none'}
									id={'codesnippet'} name={'codesnippet'} onChange={() => {
								}} value={'<form action="https://sendpoint.io/id/YOUR_FORM_ID" method="POST">\n' +
								'\t<input type="text" name="name"/>\n' +
								'\t<input type="email" name="email"/>\n' +
								'\t<input type="tel" name="phone"/>\n' +
								'\t<input type="submit" value="send"/>\n' +
								'</form>'} key={'codesnippet'} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
		;
};

export default HeroAlt;
