import React, { Fragment } from 'react';
import { ChevronLeft, ChevronRight } from "heroicons-react";
import { Navigation } from '../constants';

const MiniPagination = ({ callback }) => {
    return (
        <div className="flex-1 inline-flex float-right justify-between gap-3 sm:justify-end">
            <a onClick={(e) => callback(e, Navigation.PREVIOUS)} className="cursor-pointer inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <ChevronLeft className={"text-gray-400"} />
            </a>
            <a onClick={(e) => callback(e, Navigation.NEXT)} className="cursor-pointer inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <ChevronRight className={"text-gray-400"}/>
            </a>
        </div>
    )
};

export default MiniPagination;
