import React, { Fragment } from "react";
import HomeHero from "../../components/hero/HomeHero";
import TrustLogos from "../../components/TrustLogos";
import InfoBar from "../../components/InfoBar";
import FAQSection from "../../components/FAQSection";
import CTASection from "../../components/CTASection";
import HomeModernHero from "../../components/hero/HomeModernHero";
import HeroAlt from '../../components/hero/HeroAlt';
import TrustLogosLeft from '../../components/TrustLogosLeft';

const Home = () => {
    return (
        <Fragment>
            <HeroAlt/>
            {/*<HomeHero/>*/}
            <TrustLogos/>
            <InfoBar/>
            <FAQSection/>
            <CTASection/>
        </Fragment>
    )
};

export default Home;
