import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logos/sendpoint-logo-purple.svg';
import { LockClosedOutline, MailOpenOutline } from 'heroicons-react';

const ThankYou = () => {
	const returnToOriginalSite = () => {
		return window.history.go(-1);
	};

	return (
		<Fragment>
			<div className="relative bg-gray-50 overflow-hidden">
				<div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
					<div className="relative h-full max-w-7xl mx-auto">
						<svg
							className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
							width="404" height="784" fill="none" viewBox="0 0 404 784">
							<defs>
								<pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20"
										 patternUnits="userSpaceOnUse">
									<rect x="0" y="0" width="4" height="4" className="text-gray-200"
										  fill="currentColor" />
								</pattern>
							</defs>
							<rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
						</svg>
						<svg
							className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
							width="404" height="784" fill="none" viewBox="0 0 404 784">
							<defs>
								<pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20"
										 patternUnits="userSpaceOnUse">
									<rect x="0" y="0" width="4" height="4" className="text-gray-200"
										  fill="currentColor" />
								</pattern>
							</defs>
							<rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
						</svg>
					</div>
				</div>

				<div className="relative grid justify-items-center items-center h-screen">
					<main className="">
						<div className="">
							<div className="">
								<div
									className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all m-4 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
									role="dialog" aria-modal="true" aria-labelledby="modal-headline">
									<div>
										<div
											className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
											<MailOpenOutline className={'h-6 w-6 text-indigo-600'} />
										</div>
										<div className="mt-3 text-center sm:mt-5">
											<h3 className="text-lg leading-6 font-medium text-gray-900"
												id="modal-headline">
												Thank You!
											</h3>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													We've received your submission.
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-6">
										<button onClick={returnToOriginalSite} type="button"
												className="cursor-pointer inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
											Return to site
										</button>
									</div>
								</div>
							</div>
							<div>
								<Link to={'/?ref=thank-you-page'}>
									<p className={'text-xs text-center text-gray-500'}>Contact Forms Powered by</p>
									<img className={'h-7 w-auto mx-auto'} src={Logo} alt={'Sendpoint.io'} />
								</Link>
							</div>
						</div>


					</main>
				</div>
			</div>
		</Fragment>
	);
};

export default ThankYou;
