import React, { Fragment, useState } from 'react';
import { CheckCircle, ExclamationCircle } from "heroicons-react";

interface IActionPanel {
    color?: 'red' | 'green' | 'indigo' | 'amber' | 'blue',
    titleText?: string,
    descriptionText?: string,
    buttonText?: string,
    entityName: string,
    callback: Function,
    hideCallback: Function
}

const ActionPanel = ({
                         entityName,
                         color = 'red',
                         titleText = `Delete "${entityName}"`,
                         descriptionText = "Once you delete this form, all of it's submissions will be deleted.",
                         buttonText = 'Delete form',
                         callback,
                         hideCallback
                     }: IActionPanel) => {

    const [entityNameMatches, setEntityNameMatches] = useState(false);
    const [actualEntityName, setActualEntityName] = useState(null);

    const handleUpdateEntityName = (userInput) => {
        setActualEntityName(userInput);
        setEntityNameMatches(userInput === entityName)
    }

    return (
        <Fragment>
            <div className="fixed z-10 inset-0 overflow-y-auto action-panel">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-600 opacity-50"></div>
                    </div>

                    {/*This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true">&#8203;</span>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {titleText}
                            </h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>
                                    {descriptionText}
                                </p>
                            </div>
                            <div className="mt-5">
                                <div>
                                    <label htmlFor="email"
                                           className="block text-sm font-medium text-gray-700">Type name below to confirm deletion: <code className={`bg-${color}-100 p-1 rounded font-bold`}>{entityName}</code></label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            onChange={(e) => handleUpdateEntityName(e.target.value)}
                                            className={`${entityNameMatches ? 'focus:border-green-500 focus:shadow-outline-green' : `focus:border-${color}-300 focus:shadow-outline-${color}` } my-2 form-input block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                            placeholder={entityName} value={actualEntityName} aria-invalid={entityNameMatches}
                                            />
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        { entityNameMatches && actualEntityName ? <CheckCircle className={`h-5 w-5 text-green-500`}/> : <ExclamationCircle className={`h-5 w-5 text-${color}-500`}/>}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => callback()}
                                    disabled={!entityNameMatches}
                                    type="button"
                                    className={`${!entityNameMatches ? `cursor-not-allowed` : `cursor-pointer`} inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2`}>
                                    {buttonText}
                                </button>
                                <button
                                    onClick={() => hideCallback()}
                                    className="ml-2 inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default ActionPanel;
