import React, { Fragment } from 'react';
import FeaturesSection from '../../components/FeaturesSection';

const Features = () => {
    return (
        <Fragment>
            <FeaturesSection/>
        </Fragment>
    )
};

export default Features;
