import React, { Fragment, useEffect, useState } from 'react';
import Loader from "./common/Loader";
import SelectInput from "./inputs/SelectInput";

const FormBuilderSlideOver = ({ displayCallback, saveCallback,  display, input: inputObj }) => {
    const inputTypesArr = [
        { id: 0, item: "text", selected: false },
        { id: 1, item: "tel", selected: false },
        { id: 2, item: "email", selected: false },
        { id: 3, item: "number", selected: false },
        { id: 4, item: "textarea", selected: false },
        { id: 5, item: "button", selected: false },
        { id: 6, item: "url", selected: false },
        { id: 7, item: "file", selected: false },
        { id: 8, item: "password", selected: false },
        { id: 9, item: "radio", selected: false },
        { id: 10, item: "checkbox", selected: false },
        { id: 11, item: "search", selected: false },
        { id: 12, item: "image", selected: false },
        { id: 13, item: "hidden", selected: false },
        { id: 14, item: "reset", selected: false },
        { id: 15, item: "range", selected: false },
        { id: 16, item: "color", selected: false },
        { id: 17, item: "time", selected: false },
        { id: 18, item: "date", selected: false },
        { id: 19, item: "month", selected: false },
        { id: 20, item: "week", selected: false },
        { id: 21, item: "datetime-local", selected: false }
    ];
    // { submit: 5, type: "text", name: "phone", required: false, placeholder: "Phone: 5", label: "Phone" }

    const [input, setInput] = useState({} as any);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [inputTypes, setInputTypes] = useState(inputTypesArr);

    const handleSelectedInputType = (e) => {
        const updatedInput = {...input};
        updatedInput.type = inputTypes[e].item;
        console.log('inputTypes[e]:', inputTypes[e]);
        setInput(updatedInput);
    };

    const handleClosePanel = () => {
        setInput(null);
        displayCallback(false);
    };

    const handleSave = (e) => {
        const updatedInput = {...input};
        setInput(updatedInput);
        saveCallback(updatedInput);
        handleClosePanel();
    };

    const handleChange = (event) => {
        const updatedInput = {...input};
        console.log('event.target.name:', event.target.name);
        console.log('event.target.type:', event.target.type);
        console.log('event.target.value:', event.target.value);
        console.log('event.target.checked:', event.target.checked);
        updatedInput[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        console.log('updatedInput:', updatedInput);
        // updatedInput[event.target.name] = event.target.value;
        setInput(updatedInput);
    };

    useEffect(() => {
        if (inputObj) {
            setInput(inputObj);
        }
    }, [inputObj, display]);


    return input ? (
        console.log('input:', input),
            <Fragment>
                <div className={`${display ? "fixed inset-0 overflow-hidden" : "hidden"}`}>
                    <div className="absolute inset-0 overflow-hidden">
                        <section className="absolute inset-y-0 pl-16 max-w-full right-0 flex">

                            <div
                                className={`${display ? "translate-x-0" : "translate-x-full"} w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700`}>
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <header className="space-y-1 py-6 px-4 bg-orange-700 sm:px-6">
                                            <div className="flex items-center justify-between space-x-3">
                                                <h2 className="text-lg leading-7 font-medium text-white">
                                                    {input.name ? `Edit ${input.name[0].toUpperCase() + input.name.slice(1, input.length)} Field` : `Edit Field`}
                                                </h2>
                                                <div className="h-7 flex items-center">
                                                    <button onClick={() => handleClosePanel()} aria-label="Close panel"
                                                            className="text-orange-200 hover:text-white transition ease-in-out duration-150">
                                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                                             stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-sm leading-5 text-orange-300">
                                                    Get started by filling in the information below to create your new project.
                                                </p>
                                            </div>
                                        </header>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                <div className="space-y-6 pt-6 pb-5">
                                                    {input.hasOwnProperty('type') ? (
                                                        <div className="space-y-1 relative">
                                                            <SelectInput labelText={"Input Type"}
                                                                         callback={(e) => handleSelectedInputType(e)}
                                                                         listItems={inputTypes}
                                                                         initialValue={input.type}/>

                                                        </div>) : null}

                                                    {input.hasOwnProperty('name') ?
                                                        (<div className="space-y-1">
                                                            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-900">
                                                                Name Attribute
                                                            </label>
                                                            <div className="relative rounded-md shadow-sm">
                                                                <input id="name"
                                                                       onChange={(e) => handleChange(e)}
                                                                       name={"name"}
                                                                       className="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                                                                       value={input.name}/>
                                                            </div>
                                                        </div>) : null}
                                                    {input.hasOwnProperty('placeholder') ? (<div className="space-y-1">
                                                        <label htmlFor="placeholder"
                                                               className="block text-sm font-medium leading-5 text-gray-900">
                                                            Placeholder
                                                        </label>
                                                        <div className="relative rounded-md shadow-sm">
                                                            <input id="placeholder"
                                                                   onChange={(e) => handleChange(e)}
                                                                   name={"placeholder"}
                                                                   className="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                                                                   value={input.placeholder}/>
                                                        </div>
                                                    </div>) : null}

                                                    {input.hasOwnProperty('label') ? (<div className="space-y-1">
                                                        <label htmlFor="label"
                                                               className="block text-sm font-medium leading-5 text-gray-900">
                                                            Label
                                                        </label>
                                                        <div className="relative rounded-md shadow-sm">
                                                            <input id="placeholder"
                                                                   onChange={(e) => handleChange(e)}
                                                                   name={"label"}
                                                                   className="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                                                                   value={input.label}/>
                                                        </div>
                                                    </div>) : null}

                                                    {input.hasOwnProperty('required') ? (<div className="space-y-1">
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input id="required" type="checkbox"
                                                                       onChange={(e) => handleChange(e)}
                                                                       name={"required"}
                                                                       value={input.required}
                                                                       className="form-checkbox h-4 w-4 text-orange-600 transition duration-150 ease-in-out"/>
                                                            </div>
                                                            <div className="ml-3 text-sm leading-5">
                                                                <label htmlFor="required"
                                                                       className="font-medium text-gray-700">Required</label>
                                                                <p className="text-gray-500">Is this a required input field?</p>
                                                            </div>
                                                        </div>
                                                    </div>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                                    <span className="inline-flex rounded-md shadow-sm">
                                      <button onClick={() => handleClosePanel()} type="button" className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                        Cancel
                                      </button>
                                    </span>
                                        <span className="inline-flex rounded-md shadow-sm">
                                      <button onClick={(e) => handleSave(e)} type="submit"
                                              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:border-orange-500 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                                        Save
                                      </button>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Fragment>
    ) : null;
};

export default FormBuilderSlideOver;
