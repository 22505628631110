import React from 'react';
import CodeSnippet from '../assets/img/codesnippet.png';
import Scooped from '../assets/img/scooped.png';
import CareerDevs from '../assets/img/careerdevs.png';
import Faang from '../assets/img/faang.png';
import EntryDev from "../assets/img/entrydev.png"

const TrustLogos = () => {
	return (
		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
				<div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5 opacity-50">
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img className="h-8 sm:h-12 w-auto" src={CodeSnippet}
							 alt="CodeSnippet"/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img className="h-8 sm:h-12 w-auto" src={Scooped}
							 alt="Scooped"/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img className="h-8 sm:h-12 w-auto" src={CareerDevs}
							 alt="CareerDevs"/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
						<img className="h-8 sm:h-12 w-auto" src={Faang}
							 alt="Faang"/>
					</div>
					<div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
						<img className="h-8 sm:h-12 w-auto" src={EntryDev}
							 alt="EntryDev"/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrustLogos;
