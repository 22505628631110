import React, { Fragment } from 'react';
import { InboxInOutline } from "heroicons-react";
import { Line } from "react-chartjs-2";
import { IForm } from "../types";

const DemoFormCard = () => {
    const parseTimeSliceData = (data) => {
        const counts = [];
        const labels = [];
        const formObjAsArr = Object.entries(data);
        formObjAsArr.map(item => {
            labels.push(item[0]);
            counts.push({ x: item[0], y: item[1] });
        });
        return { labels, counts };
    };


    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    display: false,
                    weight: 0.1
                }
            ],
            yAxes: [
                {
                    display: false,
                    weight: 0.1
                }
            ]
        },
        legend: {
            display: false
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0
            }
        }
    };

    const purple = 'rgba(128,90,213,.55)';
    const green = 'rgba(104,211,145,0.25)';

    const currentTimeSliceSubmissions = {
        "status": 200,
        "payload": {
            "2021-01-17T00:00:00Z": 45,
            "2021-01-18T00:00:00Z": 32,
            "2021-01-19T00:00:00Z": 31,
            "2021-01-20T00:00:00Z": 57,
            "2021-01-21T00:00:00Z": 91,
            "2021-01-22T00:00:00Z": 23,
            "2021-01-23T00:00:00Z": 17
        }
    }
    const previousTimeSliceSubmissions = {
        "status": 200,
        "payload": {
            "2021-01-10T00:00:00Z": 27,
            "2021-01-11T00:00:00Z": 29,
            "2021-01-12T00:00:00Z": 27,
            "2021-01-13T00:00:00Z": 45,
            "2021-01-14T00:00:00Z": 108,
            "2021-01-15T00:00:00Z": 22,
            "2021-01-16T00:00:00Z": 22
        }
    }

    const { labels, counts: currentCounts } = parseTimeSliceData(currentTimeSliceSubmissions.payload);
    const { counts: previousCounts } = parseTimeSliceData(previousTimeSliceSubmissions.payload);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: `Current`,
                data: currentCounts,
                cubicInterpolationMode: 'monotone',
                lineTension: 0.4,
                fill: true,
                backgroundColor: 'rgba(233,216,253,0.25)',
                borderColor: purple,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'round',
                borderWidth: 5,
                pointBorderWidth: 0,
                pointHitRadius: 100,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: "rgba(255,255,255,0)",
                pointHoverBorderColor: purple,
                pointHoverBorderWidth: 0,
                pointRadius: 1
            },
            {
                label: `Previous`,
                data: previousCounts,
                cubicInterpolationMode: 'monotone',
                lineTension: 0.4,
                fill: true,
                backgroundColor: 'rgba(165,253,195,0.1)',
                borderColor: green,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'round',
                borderWidth: 5,
                pointBorderWidth: 0,
                pointHitRadius: 100,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: green,
                pointHoverBorderColor: green,
                pointHoverBorderWidth: 0,
                pointRadius: 1
            }
        ]
    };

    const lineProps = {
        data: chartData,
        options: chartOptions
    } as any;

    const exampleForm: IForm = {
        customId: "faang",
        submissionCount: 457,
        formName: "FAANG.io ProductHunt Launch"
    }
    return (
        <Fragment>
            <div className='bg-white overflow-hidden shadow rounded-lg'>
                <div className='px-4 py-2 sm:px-4'>
                    <div className="flex inline-block justify-between">
                        <h4 className={'font-bold'}>{exampleForm.formName}</h4>
                        <div className={"inline-block align-middle justify-end"}>
                            <InboxInOutline className={'inline-flex text-gray-500 h-3.5 w-3.5 mr-1'}/>
                            <p className={"inline-flex text-gray-500 text-xs"}>{exampleForm.submissionCount}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <Line {...lineProps} />
                </div>
                <div className='border-t border-gray-200 px-4 py-2 cursor-pointer text-center'>
                    <p style={{ fontSize: '.7rem' }}
                       className={'inline-block text-center font-mono text-gray-400 font-semibold select-all cursor-pointer'}>{`https://sendpoint.io/id/${exampleForm.customId}`}</p>
                </div>
            </div>
        </Fragment>
    )
};

export default DemoFormCard;
