import React, { Fragment } from 'react';
import FormSection from "../../components/FormSection";

const Forms = () => {
    return (
        <Fragment>
            <FormSection/>
        </Fragment>
    )
};

export default Forms;
