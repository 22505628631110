import React, { Fragment } from 'react';

const Card = ({ children, className = '' }: { children: any, className: any}) => {
	return (
		<div className={`${className} mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow`}>
			<div>
				<div>{children}</div>
			</div>
		</div>
	);
};

export default Card;
