import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import NotificationContext from "../../context/NotificationContext";
import FormDetails from "../../components/FormDetails";
import {emitToastNotification} from "../../utils/notificationUtils";

const FormSettings = () => {
    const { state: auth } = useContext(AuthContext);
    const { state: notification, dispatch } = useContext(NotificationContext);

    const params: any = useParams();
    const { formCustomId } = params;

    const [formMetaData, setFormMetaData]: any = useState({});

    const fetchFormMetaData = async () => {
        const res = await fetch(`/api/forms/${formCustomId}/settings`);
        const data = await res.json();
        setFormMetaData(data.payload.form);
    };

    const handleSaveUpdatedFormMetaData = async (e) => {
        e.preventDefault();

        let params: any = {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${auth.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formMetaData)
        };

        const res = await fetch(`/api/forms/${formCustomId}/update`, params);
        const notification = await res.json();

        emitToastNotification(dispatch, notification, 5000);

    };

    const handleChange = (e) => {
        const updatedFormMetaData = {
            ...formMetaData,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        };

        setFormMetaData(updatedFormMetaData);
    };

    useEffect(() => {
        fetchFormMetaData().catch(err => err);
    }, []);


    return formMetaData ? (
        <Fragment>
            <FormDetails settings={true} notification={notification} handleChange={handleChange} handleSave={handleSaveUpdatedFormMetaData} formMetaData={formMetaData} />
        </Fragment>
    ) : null;
};

export default FormSettings;
