import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import AuthContext from '../context/AuthContext';
import IntervalContext from "../context/IntervalContext";
import { LOADING } from "../actions";
import DashboardContext from "../context/DashboardContext";

const FormChart = ({ formCustomId }: { formCustomId: string }) => {
	const { state: auth } = useContext(AuthContext);
	const { state: intervalState } = useContext(IntervalContext);
	const { state: dashboard, dispatch } = useContext(DashboardContext);
	const { interval } = intervalState;
	const [currentTimeSliceSubmissions, setCurrentTimeSliceSubmissions] = useState([]);
	const [previousTimeSliceSubmissions, setPreviousTimeSliceSubmissions] = useState([]);
	const [labels, setLabels] = useState([]);

	const fetchCurrentTimeSliceFormData = async () => {
		const res = await fetch(`/api/submissions/${formCustomId}/time-series?interval=${interval.toLowerCase()}`);
		const data = await res.json();
		parseCurrentTimeSliceFormData(data.payload);
	};

	const fetchPreviousTimeSliceFormData = async () => {
		const res = await fetch(`/api/submissions/${formCustomId}/time-series?interval=${interval.toLowerCase()}&lookback=true`);
		const data = await res.json();
		parsePreviousTimeSliceFormData(data.payload);
		if (dashboard.loading) dispatch({ type: LOADING, payload: false });
	};

	const parseCurrentTimeSliceFormData = (data) => {
		setCurrentTimeSliceSubmissions([]);
		const counts = [];
		const labels = [];
		const formObjAsArr = Object.entries(data);
		formObjAsArr.map(item => {
			labels.push(item[0]);
			counts.push({ x: item[0], y: item[1] });
		});
		setLabels(labels);
		setCurrentTimeSliceSubmissions(counts);
		if (dashboard.loading) dispatch({ type: LOADING, payload: false });
	};

	const parsePreviousTimeSliceFormData = (data) => {
		setPreviousTimeSliceSubmissions([]);
		const counts = [];
		const labels = [];
		const formObjAsArr = Object.entries(data);
		formObjAsArr.map(item => {
			labels.push(item[0]);
			counts.push({ x: item[0], y: item[1] });
		});
		setPreviousTimeSliceSubmissions(counts);
	};

	useEffect(() => {
		fetchCurrentTimeSliceFormData().catch(err => err);
		fetchPreviousTimeSliceFormData().catch(err => err);
	}, [interval]);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [
				{
					display: false,
					weight: 0.1
				}
			],
			yAxes: [
				{
					display: false,
					weight: 0.1
				}
			]
		},
		legend: {
			display: false
		},
		layout: {
			padding: {
				left: 0,
				right: 0,
				top: 10,
				bottom: 0
			}
		}
	};

	const purple = 'rgba(128,90,213,.55)';
	const green = 'rgba(104,211,145,0.25)';

	const chartData = {
		labels: labels,
		datasets: [
			{
				label: `Current`,
				data: currentTimeSliceSubmissions,
				cubicInterpolationMode: 'monotone',
				lineTension: 0.4,
				fill: true,
				backgroundColor: 'rgba(233,216,253,0.25)',
				borderColor: purple,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'round',
				borderWidth: 5,
				pointBorderWidth: 0,
				pointHitRadius: 100,
				pointHoverRadius: 0,
				pointHoverBackgroundColor: "rgba(255,255,255,0)",
				pointHoverBorderColor: purple,
				pointHoverBorderWidth: 0,
				pointRadius: 1
			},
			{
				label: `Previous`,
				data: previousTimeSliceSubmissions,
				cubicInterpolationMode: 'monotone',
				lineTension: 0.4,
				fill: true,
				backgroundColor: 'rgba(165,253,195,0.1)',
				borderColor: green,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'round',
				borderWidth: 5,
				pointBorderWidth: 0,
				pointHitRadius: 100,
				pointHoverRadius: 0,
				pointHoverBackgroundColor: green,
				pointHoverBorderColor: green,
				pointHoverBorderWidth: 0,
				pointRadius: 1
			}
		]
	};

	const lineProps = {
		data: chartData,
		options: chartOptions
	} as any;

	return !dashboard.loading ? <Line {...lineProps} /> : null;
};
export default FormChart;
