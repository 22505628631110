import React, {Fragment, useContext, useState} from 'react';
import Toast from './Toast';
import AlertBar from './AlertBar';
import AuthContext from '../context/AuthContext';
import {Application, NotificationType, Plan} from '../constants';
import Tag from './Tag';
import {Link, useHistory} from 'react-router-dom';
import ActionPanel from './ActionPanel';
import {INotification} from '../types/notification';
import {TrashOutline, X} from 'heroicons-react';
import Button from './inputs/Button';

const FormDetails = ({notification, handleChange, handleSave, formMetaData, settings = false}) => {
    const {state, dispatch} = useContext(AuthContext);
    const {email} = state.user;
    const {type: plan} = state.user.plan;
    const isPlanOne = plan === Plan.PLAN_ONE;
    const isPlanTwo = plan === Plan.PLAN_TWO;
    const isPlanThree = plan === Plan.PLAN_THREE;
    const [displayDeleteFormModal, setDisplayDeleteFormModal] = useState(false);
    const history = useHistory();
    const userIsNotFormOwner = (email !== formMetaData.owner) && settings;

    const handleDelete = async () => {
        const res = await fetch(`/api/forms/${formMetaData.customId}/delete`, {method: 'POST'});
        const notification: INotification = await res.json();
        if (notification.type === NotificationType.SUCCESS) {
            history.push('/dashboard/forms');
        }
    };

    const handleDisplayDeleteFormModal = () => {
        setDisplayDeleteFormModal(!displayDeleteFormModal);
    };

    return formMetaData ? (
        <Fragment>
            <Fragment>
                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Form Information</h3>
                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                    Basic form information
                                </p>
                                {settings ?
                                    <div>
                                        <br/>
                                        <br/>
                                        <p className="mt-1 text-sm leading-5 text-gray-600">
                                            <b>Created On:</b> {formMetaData.createdOn}
                                        </p>
                                        { userIsNotFormOwner ?
                                            (<p className="mt-1 text-sm leading-5 text-gray-600">
                                                <b>Form Owner:</b> {formMetaData.owner}
                                            </p>) : null}
                                    </div> : null}
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            {settings && email && formMetaData && userIsNotFormOwner ? (
                                <AlertBar color={'red'} className={'mb-3'}>
                                    Form settings are in read-only mode since this account is not the form owner
                                </AlertBar>
                            ) : null}
                            <form onSubmit={(e) => handleSave(e)}>
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="formName"
                                                       className="block text-sm font-medium leading-5 text-gray-700">Form
                                                    Name</label>
                                                <input id={'formName'}
                                                       type={'text'}
                                                       name={'formName'}
                                                       value={formMetaData.formName}
                                                       disabled={userIsNotFormOwner}
                                                       onChange={(e) => handleChange(e)}
                                                       className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <div className="flex justify-between">
                                                    <label htmlFor="customId"
                                                           className="block text-sm font-medium leading-5 text-gray-700">Form
                                                        Custom ID</label>
                                                    {isPlanOne ? <span className="text-sm text-gray-500"><Link
                                                        to={'/dashboard/billing'}><Tag color={'indigo'}
                                                                                       text={'UPGRADE'}/></Link></span> : null}
                                                </div>
                                                <input id={'customId'}
                                                       type={'text'}
                                                       name={'customId'}
                                                       placeholder={isPlanOne ? 'Auto-generated for free plans' : ''}
                                                       value={formMetaData.customId}
                                                       disabled={isPlanOne || userIsNotFormOwner}
                                                       onChange={(e) => handleChange(e)}
                                                       className={`${isPlanOne || userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button
                                            type={'submit'}
                                            className="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <AlertBar className={'mt-3'}>
                                Form Custom ID <b>must be unique</b>
                            </AlertBar>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block">
                    <div className="py-5">
                        <div className="border-t border-gray-200"/>
                    </div>
                </div>

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Spam Protection</h3>
                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                    Add Google reCAPTCHA keys to help prevent spam form submissions.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={(e) => handleSave(e)}>
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id={'recaptchaEnabled'}
                                                               name={'recaptchaEnabled'}
                                                               type="checkbox"
                                                               checked={formMetaData.recaptchaEnabled}
                                                               disabled={userIsNotFormOwner}
                                                               onChange={(e) => handleChange(e)}
                                                               className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out`}/>
                                                    </div>
                                                    <div className="ml-3 text-sm leading-5">
                                                        <label htmlFor="recaptchaEnabled"
                                                               className="font-medium text-gray-700">Google
                                                            reCAPTCHA</label>
                                                        <p className="text-gray-500">Enable for better spam
                                                            protection.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="recaptchaSiteKey"
                                                       className="block text-sm font-medium leading-5 text-gray-700">Google
                                                    reCAPTCHA Site Key</label>
                                                <input id={'recaptchaSiteKey'}
                                                       type={'text'}
                                                       name={'recaptchaSiteKey'}
                                                       value={formMetaData.recaptchaSiteKey}
                                                       disabled={userIsNotFormOwner}
                                                       onChange={(e) => handleChange(e)}
                                                       className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="recaptchaSecretKey"
                                                       className="block text-sm font-medium leading-5 text-gray-700">Google
                                                    reCAPTCHA Secret Key</label>
                                                <input id={'recaptchaSecretKey'}
                                                       type={'text'}
                                                       name={'recaptchaSecretKey'}
                                                       value={formMetaData.recaptchaSecretKey}
                                                       disabled={userIsNotFormOwner}
                                                       onChange={(e) => handleChange(e)}
                                                       className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button
                                            type={'submit'}
                                            className="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block">
                    <div className="py-5">
                        <div className="border-t border-gray-200"/>
                    </div>
                </div>

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                    Decide how you'd like to receive notifications for this form.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={(e) => handleSave(e)}>
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <fieldset>
                                            <legend className="text-base leading-6 font-medium text-gray-900">Available
                                                Notifications
                                            </legend>
                                            <div className="mt-4">

                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id={'emailEnabled'}
                                                               name={'emailEnabled'}
                                                               type="checkbox"
                                                               checked={formMetaData.emailEnabled}
                                                               disabled={userIsNotFormOwner}
                                                               onChange={(e) => handleChange(e)}
                                                               className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out`}/>
                                                    </div>
                                                    <div className="ml-3 text-sm leading-5">
                                                        <label htmlFor="emailEnabled"
                                                               className="font-medium text-gray-700">Email
                                                            Notifications</label>
                                                        <p className="text-gray-500">Receive email notifications when
                                                            new submissions are received for this form</p>
                                                    </div>
                                                </div>

                                                {
                                                    formMetaData.emailEnabled ? (
                                                        <div className="col-span-6 sm:col-span-4 my-4">
                                                            <label htmlFor="emailAddressList"
                                                                   className="block text-sm font-medium leading-5 text-gray-700">Email
                                                                Address List (Comma Separated)</label>
                                                            <input id="emailAddressList"
                                                                   type={'text'}
                                                                   name={'emailAddressList'}
                                                                   required={formMetaData.emailEnabled}
                                                                   placeholder={'elon@spacex.com, jeff@amazon.com, tim@sendpoint.io'}
                                                                   value={formMetaData.emailAddressList?.toString()}
                                                                   disabled={userIsNotFormOwner}
                                                                   onChange={(e) => handleChange(e)}
                                                                   className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                                            <p className="pt-2 text-xs text-gray-500">
                                                                Add up to 3 email addresses (comma separated) that will
                                                                receive email notifications.
                                                            </p>
                                                        </div>
                                                    ) : null
                                                }

                                                <div className="flex items-start mt-4">
                                                    <div className="flex items-center h-5">
                                                        <input id={'smsEnabled'}
                                                               name={'smsEnabled'}
                                                               type="checkbox"
                                                               checked={formMetaData.smsEnabled}
                                                               disabled={userIsNotFormOwner}
                                                               onChange={(e) => handleChange(e)}
                                                               className={`${userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out`}/>
                                                    </div>
                                                    <div className="ml-3 text-sm leading-5">
                                                        <label htmlFor="smsEnabled"
                                                               className="font-medium text-gray-700">SMS
                                                            Notifications</label>
                                                        <p className="text-gray-500">Receive text message alerts when
                                                            new submissions are received for this form</p>
                                                    </div>
                                                </div>

                                                {
                                                    formMetaData.smsEnabled ? (
                                                        <div className="col-span-6 sm:col-span-4 my-4">
                                                            <label htmlFor="smsPhoneNumberList"
                                                                   className="block text-sm font-medium leading-5 text-gray-700">SMS
                                                                Phone Numbers</label>
                                                            <input id="smsPhoneNumberList"
                                                                   type={'text'}
                                                                   name={'smsPhoneNumberList'}
                                                                   placeholder={'+15551234567, +12813308004'}
                                                                   value={formMetaData.smsPhoneNumberList?.toString()}
                                                                   required={formMetaData.smsEnabled}
                                                                   disabled={userIsNotFormOwner}
                                                                   onChange={(e) => handleChange(e)}
                                                                   className={`${isPlanOne || userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                                            <p className="pt-2 text-xs text-gray-500">
                                                                Add up to 2 phone numbers (comma separated) that will
                                                                receive SMS notifications.
                                                            </p>
                                                        </div>
                                                    ) : null
                                                }

                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button
                                            type={'submit'}
                                            className="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div className="hidden sm:block">
                    <div className="py-5">
                        <div className="border-t border-gray-200"/>
                    </div>
                </div>

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Features</h3>
                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                    Decide which features you'd like to enable.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <form onSubmit={(e) => handleSave(e)}>
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <fieldset>
                                            <legend className="text-base leading-6 font-medium text-gray-900">Additional
                                                Features
                                            </legend>
                                            <div className="mt-4">
                                                <div className="mt-4">
                                                    <div className="col-span-6 sm:col-span-4">

                                                        <div className="flex justify-between">
                                                            <label htmlFor="customId"
                                                                   className="block text-sm font-medium leading-5 text-gray-700">Redirect
                                                                URL</label>
                                                            {isPlanOne ? <span className="text-sm text-gray-500"><Link
                                                                to={'/dashboard/billing'}><Tag color={'indigo'}
                                                                                               text={'UPGRADE'}/></Link></span> : null}
                                                        </div>
                                                        <p className="text-gray-500 text-sm">Users will be redirected to
                                                            a custom page upon form submissions</p>
                                                        <input id="redirectUrl"
                                                               type={'url'}
                                                               name={'redirectUrl'}
                                                               placeholder={formMetaData.redirectUrl || Application.DEFAULT_REDIRECT_URL}
                                                               disabled={plan === Plan.PLAN_ONE || userIsNotFormOwner}
                                                               value={formMetaData.redirectUrl}
                                                               onChange={(e) => handleChange(e)}
                                                               className={`${isPlanOne || userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}/>
                                                    </div>
                                                </div>

                                                <div className="mt-4 flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id={'fileUploadEnabled'}
                                                               name={'fileUploadEnabled'}
                                                               type="checkbox"
                                                               disabled={isPlanOne || userIsNotFormOwner}
                                                               checked={formMetaData.fileUploadEnabled}
                                                               onChange={(e) => handleChange(e)}
                                                               className={`${isPlanOne || userIsNotFormOwner ? 'bg-gray-200 text-gray-400' : ''} form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out`}/>
                                                    </div>
                                                    <div className="ml-3 text-sm leading-5 flex-justify-between w-full">
                                                        <div className={'flex justify-between'}>
                                                            <label htmlFor="uploadEnabled"
                                                                   className="font-medium text-gray-700">File
                                                                Uploads</label>
                                                            {isPlanOne ? <span className="text-sm text-gray-500"><Link
                                                                to={'/dashboard/billing'}><Tag color={'indigo'}
                                                                                               text={'UPGRADE'}/></Link></span> : null}
                                                        </div>
                                                        <p className="text-gray-500">Enable file uploads</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </fieldset>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button
                                            type={'submit'}
                                            className="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                {settings && !userIsNotFormOwner ? (
                    <Fragment>
                        <div className="hidden sm:block">
                            <div className="py-5">
                                <div className="border-t border-gray-200"/>
                            </div>
                        </div>

                        <div className="mt-10 sm:mt-0">
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Delete</h3>
                                        <p className="mt-1 text-sm leading-5 text-gray-600">
                                            Permanently delete this form and all it's submissions.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:mt-0 md:col-span-2">
                                    <Button
                                        disabled={userIsNotFormOwner}
                                        callback={handleDisplayDeleteFormModal}
                                        py={3} px={5} textSize={'xs'} color={displayDeleteFormModal ? 'indigo' : 'red'}
                                        icon={displayDeleteFormModal ?
                                            <X className={"text-right h-4 w-4 text-white-400"}/> :
                                            <TrashOutline className={"text-right h-4 w-4 text-white-400"}/>}
                                        buttonText={displayDeleteFormModal ? "Cancel" : "Delete Form"}
                                    />
                                </div>

                            </div>
                        </div>
                        {displayDeleteFormModal ?
                            <ActionPanel entityName={formMetaData.formName} callback={() => handleDelete()}
                                         hideCallback={() => handleDisplayDeleteFormModal()}/> : null}
                    </Fragment>) : null}
            </Fragment>
        </Fragment>
    ) : null;
};

export default FormDetails;
