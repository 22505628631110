import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const CTASection = () => {
	return (
		<div className='bg-gray-50'>
			<div className='max-w-screen-xl lg:max-w-screen-lg mx-auto py-12 px-12 sm:px-16 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between'>
				<h2 className='text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
					Ready to dive in?
					<br />
					<span className='text-indigo-500'>Create your first form in 60 seconds.</span>
				</h2>
				<div className='mt-8 flex lg:flex-shrink-0 lg:mt-0'>
					<div className='inline-flex'>
						<Link
							to={'/register'}
							className='mt-3 w-full px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-lg text-white bg-indigo-500 shadow-md hover:bg-indigo-600 focus:outline-none focus:border-indigo-900 focus:shadow-outline-indigo active:bg-indigo-500 transition duration-150 ease-in-out sm:mt-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto'
						>
							Get started
						</Link>
					</div>
					<div className='ml-3 inline-flex'>
						<Link
							to={'/features'}
							className='mt-3 w-full px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-lg text-indigo-500 bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:border-green-900 focus:shadow-outline-gray active:bg-white transition duration-150 ease-in-out sm:mt-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto'
						>
							Learn more
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CTASection;
