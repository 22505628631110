import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import Loader from './common/Loader';
import SubmissionModal from './SubmissionModal';
import MultiSelectDropdown from './MultiSelectDropdown';
import Pagination from './Pagination';
import { Clock, ClockOutline, Eye, Trash } from 'heroicons-react';
import SubmissionModalContent from './SubmissionModalContent';
import DashboardContext from '../context/DashboardContext';
import { IgnoredTableHeaders, Paginate } from '../constants';
import { ISubmissionTableProps } from "../types";
import { useHover } from "../hooks/useHover";
import {
    CURRENT_SUBMISSION_PAGE,
    SUBMISSION_CURRENT_ID,
    SUBMISSION_DISPLAY_MODAL,
    SUBMISSION_PAGE_SIZE
} from "../actions";

const SubmissionsTable = ({ limit = Paginate.LIMIT, formCustomId }: ISubmissionTableProps) => {
    const { state: auth } = useContext(AuthContext);
    const { state: dashboard, dispatch: dashboardDispatch } = useContext(DashboardContext);
    const [submissions, setSubmissions] = useState([]);
    const [totalSubmissions, setTotalSubmissions] = useState(0);
    const [currentlySelectedSubmissions, setCurrentlySelectedSubmissions] = useState([]);
    const [submissionModalContent, setSubmissionModalContent] = useState({});
    const [tableHeaders, setTableHeaders] = useState([]);
    const [tableHeadersWithDisplayStatus, setTableHeadersWithDisplayStatus] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hoverRef, isHovered] = useHover();

    const fetchSubmissions = async () => {
        const apiUrl = formCustomId ? `/api/submissions/${formCustomId}?page=${currentPage}&limit=${limit}`
            : `/api/submissions?page=${currentPage}&limit=${limit}`;
        const res = await fetch(apiUrl);
        const data = await res.json();
        const { submissions, total } = data.payload;
        setSubmissions(submissions);
        setTotalSubmissions(total);
        generateTableHeaders(submissions);
    };

    useEffect(() => {
        dashboardDispatch({ type: SUBMISSION_PAGE_SIZE, payload: limit });
        dashboardDispatch({ type: CURRENT_SUBMISSION_PAGE, payload: currentPage });
    }, [currentPage, limit, dashboardDispatch]);

    useEffect(() => {
        fetchSubmissions().catch(err => err);
        setCurrentPage(currentPage + 1);
    }, []);

    const handleModalDisplayClick = (event: any) => {
        const submissionId = event.target.parentElement.dataset.submission || event.target.parentElement.parentElement.dataset.submission;
        dashboardDispatch({ type: SUBMISSION_CURRENT_ID, payload: submissionId });
        dashboardDispatch({ type: SUBMISSION_DISPLAY_MODAL, payload: !dashboard.displaySubmissionModal });
        setSubmissionModalContent(submissions[submissionId]);
    };

    const handleToggleSelectAllSubmissionsOnPage = (event: any) => {
        const temp: any[] = [...currentlySelectedSubmissions];
        if (event.target.checked) {
            submissions.map((submission: any) => {
                return temp.push({ submissionId: submission._id, formCustomId: submission.formCustomId });
            });
            setCurrentlySelectedSubmissions(temp as never[]);
        } else {
            setCurrentlySelectedSubmissions([]);
        }
    };

    const handleToggleSelectSingleSubmission = (event: any, submissionId: any, formCustomId: any) => {
        let temp: any[] = [...currentlySelectedSubmissions];
        if (event.target.checked) {
            temp.push({ submissionId, formCustomId });
        } else {
            temp = temp.filter(item => {
                return item.submissionId !== submissionId;
            });
        }
        setCurrentlySelectedSubmissions(temp as never[]);
    };

    const handleDeleteSubmissions = async (submissionsToBeDeletedArr: any) => {
        let params: any = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ payload: submissionsToBeDeletedArr })
        };
        const res = await fetch(`/api/submissions/delete`, params);
    };

    const generateTableHeaders = (submissions: any) => {
        const allTableHeaders: any = [];
        submissions.map((submission: any) => {
            return submission.data ? allTableHeaders.push(...Object.keys(submission.data)) : allTableHeaders.push('');
        });
        setTableHeaders(Array.from(new Set(allTableHeaders)));

        let uniqueTableHeaders = new Set(allTableHeaders);
        let uniqueTableHeadersArray = Array.from(uniqueTableHeaders);
        let tempTableHeaderWithStatus = [];

        uniqueTableHeadersArray.map(tableHeader => {
            return tempTableHeaderWithStatus.push({ name: tableHeader, display: true });
        });
        setTableHeadersWithDisplayStatus(tempTableHeaderWithStatus as never[]);
    };

    const renderTableHeaders = (tableHeaders: any) => {
        return tableHeaders.map((tableHeader: any, key: number) => {
            if (IgnoredTableHeaders.includes(tableHeader.name)) return null;
            else {
                return tableHeader.display ? (
                    <th key={key}
                        className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                        {tableHeader.name}
                    </th>
                ) : null;
            }
        });
    };

    const tableStyles = {
        tableData: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: 225
        }
    };

    const renderIndividualSubmissionColumns = (submission: any) => {
        return tableHeadersWithDisplayStatus.map((column: any, key: number) => {
            if (IgnoredTableHeaders.includes(column.name)) return null;
            else if (column.display) {
                return submission.data && submission.data[column.name] ? (
                    <td style={tableStyles.tableData} key={key}
                        className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                        {submission.data[column.name]}
                    </td>
                ) : (
                    <td key={key}
                        className='opacity-50 px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'/>
                );
            } else return null;
        });
    };

    const renderSubmissions = (submissions: any) => {
        return submissions.map((submission: any, key: number) => {
            return (
                <tr data-submission-id={submission._id} data-submission={key} data-submission-key={key} key={key}>
                    <td className={'border-b border-gray-200 '}>
                        <input
                            data-submission-id={submission._id}
                            data-submission-key={key}
                            id={`submission-checkbox-${key}`}
                            onChange={e => handleToggleSelectSingleSubmission(e, submission._id, submission.formCustomId)}
                            checked={currentlySelectedSubmissions.some(checkbox => checkbox.submissionId === submission._id)}
                            type={'checkbox'}
                            className={'bg-gray-50 mx-3 my-3 form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out'}
                        />
                    </td>
                    <td key={key}
                        className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                        {submission.formCustomId || 'Form'}
                    </td>
                    {renderIndividualSubmissionColumns(submission)}
                    <td className='w-2 justify-end px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium'>
                        {isHovered ? <div className="relative mx-2">
                            <div className="bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full">
                                {submission.submittedOn}
                                <svg className="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px"
                                     viewBox="0 0 255 255">
                                    <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                                </svg>
                            </div>
                        </div> : null}
                        <div ref={hoverRef as any}>
                            <a
                                data-timestamp={key}
                                key={key}
                                className='cursor-pointer text-gray-300 hover:text-gray-400'>
                                <ClockOutline data-timestamp={submission.submittedOn} className={'h-5 w-5'}/>
                            </a>
                        </div>
                    </td>
                    <td className='w-2 justify-end px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium'>
                        <a data-submission={key} key={key} onClick={event => handleModalDisplayClick(event)}
                           className='cursor-pointer text-gray-300 hover:text-gray-400'>
                            <Eye data-submission={key} className={'h-5 w-5'}/>
                        </a>
                    </td>
                </tr>
            );
        });
    };


    return !dashboard.loading && submissions.length ? (
        <div
            className={`${
                submissions.length > 0 ? 'transition-opacity opacity-100 duration-500 ease-in-out' : 'opacity-0 transition-opacity duration-500 ease-in-out'
            } flex flex-col`}
        >
            <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
                <div
                    className='align-middle inline-block min-w-full shadow overflow-hidden rounded-lg border-b border-gray-200'>
                    <div className='bg-white border-b border-gray-200 px-6'>
                        <div className='flex flex-wrap sm:flex-no-wrap'>
                            <div>
                                <div>
                                    {currentlySelectedSubmissions.length > 0 ? (
                                        <div className={'space-y-1 w-auto inline-block'}>
											<span className='inline-flex rounded-md shadow-sm'>
												<button
                                                    onClick={() => handleDeleteSubmissions(currentlySelectedSubmissions)}
                                                    type='button'
                                                    className='space-y-1 my-3 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:red active:bg-red-600 transition ease-in-out duration-150'
                                                >
													<Trash className={'h-4 w-4 mr-2'}/>
                                                    {currentlySelectedSubmissions.length > 1 ? `Delete ${currentlySelectedSubmissions.length} Submissions` : `Delete Submission`}
												</button>
											</span>
                                        </div>
                                    ) : (
                                        <MultiSelectDropdown
                                            tableHeadersWithDisplayStatus={tableHeadersWithDisplayStatus}
                                            setTableHeadersWithDisplayStatus={setTableHeadersWithDisplayStatus}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className='min-w-full'>
                        <thead>
                        <tr>
                            <th className={'border-b border-gray-200 bg-gray-50 w-3'}>
                                <input
                                    onClick={e => handleToggleSelectAllSubmissionsOnPage(e)}
                                    id={`submission-checkbox-selectall`}
                                    type='checkbox'
                                    className='mx-3 my-3 border-b border-gray-200 bg-gray-50 form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out'
                                />
                            </th>
                            <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                                Form ID
                            </th>
                            {renderTableHeaders(tableHeadersWithDisplayStatus)}
                            <th className='px-6 py-3 border-b border-gray-200 bg-gray-50'/>
                            <th className='px-6 py-3 border-b border-gray-200 bg-gray-50'/>
                        </tr>
                        </thead>
                        <tbody className='bg-white'>{auth.authenticated ? renderSubmissions(submissions) : null}</tbody>
                    </table>
                    <Pagination callback={() => fetchSubmissions()} totalItems={totalSubmissions}
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                pageSize={dashboard.submissionPageSize}/>
                </div>
                <SubmissionModal display={dashboard.displaySubmissionModal}>
                    <SubmissionModalContent
                        data={submissions[dashboard.currentlyDisplayedSubmissionId]}
                        pageSize={dashboard.submissionPageSize}
                        currentPage={dashboard.currentSubmissionPage}
                    />
                </SubmissionModal>
            </div>
        </div>
    ) : <div>No submissions</div>
};

export default SubmissionsTable;
