import React, { Fragment } from 'react';
import BillingSection from '../../components/BillingSection';

const Billing = () => {
    return (
        <Fragment>
            <BillingSection/>
        </Fragment>
    )
};

export default Billing;
