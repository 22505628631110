import React, { Fragment } from 'react';
import SubmissionsTable from "../../components/SubmissionsTable";

const Submissions = () => {
    return (
        <div className="py-4">
            <SubmissionsTable/>
        </div>
    )
};

export default Submissions;
