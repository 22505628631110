import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { ChevronLeft, ChevronRight } from 'heroicons-react';
import { Navigation } from '../constants';
import DashboardContext from '../context/DashboardContext';
import { CURRENT_SUBMISSION_PAGE } from "../actions";

const Pagination = ({ callback, totalItems, children, currentPage, setCurrentPage, pageSize }: { callback, totalItems?: any; children?: any; currentPage: number; setCurrentPage: Function; pageSize: number }) => {
	const { state: auth } = useContext(AuthContext);
	const { state: dashboard, dispatch: dashboardDispatch } = useContext(DashboardContext);
	const [currentItem, setCurrentItem] = useState(1);
	const [itemEndCountForPage, setItemEndCountForPage] = useState(pageSize);



	const handlePaginationNavigate = (direction: Navigation) => {
		console.log('handlePaginationNavigation')
		const INDEX_OFFSET = 1;
		const ONE_PAGE = 1;
		if (direction === Navigation.NEXT) {
			if (currentItem + pageSize < totalItems) {
				callback();
				// dashboardDispatch({ type: 'SUBMISSION_PAGE_SIZE', payload: limit });
				dashboardDispatch({ type: CURRENT_SUBMISSION_PAGE, payload: currentPage });
				setCurrentItem(currentItem + pageSize);
				itemEndCountForPage + pageSize >= totalItems ? setItemEndCountForPage(totalItems) : setItemEndCountForPage(itemEndCountForPage + pageSize);
				setCurrentPage(currentPage + 1);
				console.log('Pagination.tsx : currentPage:', currentPage)
			} else if (((currentItem + pageSize) >= totalItems)) {
				callback();
				dashboardDispatch({ type: CURRENT_SUBMISSION_PAGE, payload: currentPage });
				const OFFSET = ((totalItems % itemEndCountForPage) - 1);
				setCurrentPage(currentPage + 1);
				setCurrentItem(currentItem + pageSize);
				setItemEndCountForPage(totalItems)
			}
			// if (currentItem + pageSize > totalItems) {
			// 	console.log('here__:', currentItem + pageSize >= totalItems)
			// }

		}
		else if (direction === Navigation.PREVIOUS) {
			if (currentItem - pageSize > 0) {
				setCurrentItem(currentItem - pageSize);
				setItemEndCountForPage(itemEndCountForPage - pageSize);
				setCurrentPage(currentPage - 1);
				callback();
			}
		}
		// else {
		// 	// trigger toast or disable buttons
		// 	return;
		// }
	};

	return (
	totalItems ? (
		<nav className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
			<div className='hidden sm:block'>
				<p className='text-sm leading-5 text-gray-700'>
					Showing <span className='font-medium'>{currentItem}</span> to <span className='font-medium'>{totalItems <= itemEndCountForPage ? totalItems : itemEndCountForPage}</span> of{' '}
					<span className='font-medium'> {totalItems} </span>results
				</p>
			</div>
			{children}
			<div className='flex-1 flex gap-3 justify-between sm:justify-end'>
				<a onClick={() => handlePaginationNavigate(Navigation.PREVIOUS)}
					className='cursor-pointer inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				>
					<ChevronLeft className={'text-gray-400'} />
				</a>
				<a onClick={() => handlePaginationNavigate(Navigation.NEXT)}
					className='cursor-pointer inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				>
					<ChevronRight className={'text-gray-400'} />
				</a>
			</div>
		</nav>
	) : null);
};

export default Pagination;
