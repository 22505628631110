import React, { Fragment, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import AuthContext from '../context/AuthContext';

const SubmissionModalContent = ({ data, pageSize, currentPage }) => {
	const { state: auth } = useContext(AuthContext);
	const [userTimezone, setUserTimezone] = useState(auth.timezone);

	const renderSubmissionData = data => {
		let submissionData = data && data.data ? data.data : { message: 'No submission data available!' };
		const entries = Object.entries(submissionData);
		const ignoredHeaders = ['', 'g-recaptcha-response'];
		return entries.map((entry: any, key: number) => {
			if (ignoredHeaders.includes(entry[0])) return;
			return (
				<Fragment key={key}>
					<div className='px-4 py-5 sm:p-0'>
						<dl>
							<div className='border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5'>
								<dt className='text-sm leading-5 font-medium text-gray-500'>{entry[0]}</dt>
								<dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>{entry[1]}</dd>
							</div>
						</dl>
					</div>
				</Fragment>
			);
		});
	};

	return (
		<Fragment>
			<div className='px-4 py-5 border-b border-gray-200 sm:px-6'>
				<div className={'relative'}>
					<div className='float-right inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800'>{`ID: ${
						data ? data._id : 0
					}`}</div>
				</div>
				<h3 className='text-lg leading-6 font-medium text-gray-900'>
					<span className={'font-semibold text-indigo-600'}>{data ? data.formCustomId : 'Form'}</span>
				</h3>
				<p className='mt-1 max-w-2xl text-sm leading-5 text-gray-500'>{data ? moment(data.created_date).tz(userTimezone).format('M/D/YY hh:mm:ss') : Date.now()}</p>
			</div>
			{renderSubmissionData(data)}
		</Fragment>
	);
};

export default SubmissionModalContent;
