import React, { useState } from "react";
import { Controlled as CodeMirror } from 'react-codemirror2';
require('react-codemirror');
require('../assets/css/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/mode/htmlembedded/htmlembedded');

const CodeSnippet = ({ value, id, name, onChange, className }) => {
    let options = {
        lineNumbers: true,
        theme: 'material'
    };
    const [codeSnippet, setCodeSnippet] = useState(value)
    return (
        <CodeMirror
            value={value}
            options={options}
            onChange={(editor, data, value) => {
                setCodeSnippet(value)
            }}
            onBeforeChange={(editor, data, value) => {
                setCodeSnippet(value)
            }}
        />

    );
};

export default CodeSnippet;
