import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Check} from "heroicons-react";

const SelectInput = ({ labelText = null, initialValue, listItems, callback }) => {

    const [toggle, setToggle] = useState(false);
    const dropDownRef = useRef();
    const [selectedValue, setSelectedValue] = useState(initialValue);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        setSelectedValue(initialValue);
    }, [initialValue])

    useEffect(() => {
        const handleClickEvent = event => {
            if (dropDownRef.current && !event.target.classList.contains('select-group-input')) {
                setToggle(!toggle);
            } else if (dropDownRef.current) {
                callback(event.target.dataset.itemId);
                setSelectedValue(event.target.dataset.itemValue);
            }
        };
        document.addEventListener('mousedown', handleClickEvent);
        return () => {
            document.removeEventListener('mousedown', handleClickEvent);
        };
    }, [dropDownRef]);

    const renderListItems: any = (listItems: Array<any>) => {
        return listItems.map((listItem, key) => {
           return (
               <li key={key} data-item-id={listItem.id} data-item-value={listItem.item} id={`listbox-option-${key}`} role="option" className={`${listItem.selected ? "select-group-input text-white bg-orange-400" : "text-gray-900"} cursor-default select-none relative py-2 pl-3 pr-9`}>
                   <span data-item-id={listItem.id} data-item-value={listItem.item} className={`${listItem.selected ? "font-semibold" : "font-normal" } select-group-input block truncate`}>{ listItem.item }</span>
                   <Check data-item-id={listItem.id} data-item-value={listItem.item} className={`${listItem.selected ? "text-orange-600" : "text-white"} select-group-input h-3 w-3 absolute inset-y-0 right-0 flex items-center pr-4`} />
               </li>
           )
        });
    };


    return (
        <div className="space-y-1">
            <label id="listbox-label" className="block text-sm leading-5 font-medium text-gray-700">
                { labelText }
            </label>
            <div onClick={() => handleToggle()} className="z-10">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
                          className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <span className="block truncate">
                        { selectedValue }
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </span>
                  </button>
                </span>

                {/*Select popover, show/hide based on select state.*/}
                { toggle ? <div className="z-50 select-group-input absolute mt-1 rounded-md bg-white shadow-lg">
                    <ul ref={dropDownRef} tabIndex={-1} role={"listbox"} aria-labelledby={"listbox-label"} aria-activedescendant={"listbox-item-3"}
                        className="relative z-50 max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                        { renderListItems(listItems) }
                    </ul>
                </div> : null }
            </div>
        </div>
    )
};

export default SelectInput;
